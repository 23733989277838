import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import DOMPurify from 'dompurify';
import marked from 'marked';

class ChatMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        DOMPurify.addHook('afterSanitizeAttributes', function(node) {
            if ('target' in node) {
                node.setAttribute('target','_blank');
                node.setAttribute('rel', 'noopener noreferrer');
            }
            if (!node.hasAttribute('target')
                && (node.hasAttribute('xlink:href')
                    || node.hasAttribute('href'))) {
                node.setAttribute('xlink:show', 'new');
            }
        });
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                updated: new Date().getTime()
            });
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderTimestamp() {
        let messageTimestamp = moment(this.props.message.date),
            diff = moment().diff(messageTimestamp);

        return diff < 300000
            ? messageTimestamp.fromNow()
            : messageTimestamp.format('LT');
    }

    render() {
        const classes = ['message'];

        if (this.props.firstInChain) {
            classes.push('first-in-chain');
        }

        if (this.props.message.localOnly) {
            classes.push('local-only');
        }

        return (
            <div className={classes.join(' ')}>
                <div className="avatar" style={!this.props.firstInChain ? { height: 0 } : null}>
                    {this.props.firstInChain ? <img className="ns" alt="Avatar" src={this.props.message.avatar} /> : null}
                </div>
                <div className="content">
                    {this.props.firstInChain ? <div className="author">
                        <span>{this.props.message.author}</span>
                        <span className="timestamp" title={moment(this.props.message.date).format('LLLL')}>{this.renderTimestamp()}</span>
                    </div> : null}
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(this.props.message.content, {renderer: this.state.renderer})) }} />
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        messages: state.messages
    };
};

export default connect(mapStateToProps)(ChatMessage);