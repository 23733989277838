import moment from 'moment';

import itemFilter from '../itemFilter.js';
import {isLive} from "../twitchUtils.js";
import Entity from "../Entity";

let arrayToObject = (arr, key) => {
    return arr.reduce((acc, cur) => {
        acc[cur[key]] = cur;
        return acc;
    }, {});
}

let convertScreenToProperty = (screen) => {
    switch (screen) {
        case 'QUAD1':
            return 'first';
        case 'QUAD2':
            return 'second';
        case 'QUAD3':
            return 'third';
        case 'QUAD4':
            return 'fourth';
        case 'SOLO':
            return 'content';
        default:
            return null;
    }
}

let convertScreenToNumber = (screen) => {
    switch (screen) {
        case 'QUAD1':
            return 1;
        case 'QUAD2':
            return 2;
        case 'QUAD3':
            return 3;
        case 'QUAD4':
            return 4;
        case 'SOLO':
            return 0;
        default:
            return null;
    }
}

let mainReducer = (state = {}, action) => {
    let broadcastState = null,
        room = null,
        user = null,
        slots = null,
        items = null;

    switch (action.type) {
        case 'SHOW_DIALOG':
            let dialog = action.dialog;

            return {
                ...state,
                dialog
            };
        case 'SELECT_ITEM':
            return {
                ...state,
                selectedItems: [
                    ...state.selectedItems,
                    action.item
                ]
            };
        case 'DESELECT_ITEM':
            return {
                ...state,
                selectedItems: state.selectedItems.filter(item => item !== action.item)
            };
        case 'SET_OBSERVER_MODE':
            return {
                ...state,
                observer: {
                    ...state.observer,
                    mode: action.mode
                }
            };
        case 'MOVE_OBSERVER_SLOT':
            slots = { ...state.observer.slots};

            if (slots[action.from]) {
                if (slots[action.to]) {
                    let tmp = { ...slots[action.to]};
                    slots[action.to] = { ...slots[action.from]};
                    slots[action.from] = tmp;
                } else {
                    slots[action.to] = { ...slots[action.from]};
                    slots[action.from] = null;
                }
            }

            return {
                ...state,
                observer: {
                    ...state.observer,
                    slots
                }
            };
        case 'SET_OBSERVER_SLOT':
            slots = { ...state.observer.slots };
            slots[action.slot] = action.data;

            return {
                ...state,
                observer: {
                    ...state.observer,
                    slots
                }
            };
        case 'SET_STREAM_QUALITY':
            return {
                ...state,
                broadcastState: {
                    ...state.broadcastState,
                    streamSettings: {
                        ...state.broadcastState.streamSettings,
                        quality: action.quality
                    }
                }
            };
        case 'PARTIALLY_UPDATE_LOCAL_BROADCAST_STATE':
            let key = action.property;

            broadcastState = { ...state.broadcastState };

            switch (action.screen) {
                case 1:
                    if (!broadcastState.quadLayoutData.first) break;
                    broadcastState.quadLayoutData.first.data = {
                        ...broadcastState.quadLayoutData.first.data,
                        muted: key === 'volume' ? (!action[key]) : broadcastState.quadLayoutData.first.data.muted,
                        [key]: action[key]
                    };
                    break;
                case 2:
                    if (!broadcastState.quadLayoutData.second) break;
                    broadcastState.quadLayoutData.second.data = {
                        ...broadcastState.quadLayoutData.second.data,
                        muted: key === 'volume' ? (!action[key]) : broadcastState.quadLayoutData.second.data.muted,
                        [key]: action[key]
                    };
                    break;
                case 3:
                    if (!broadcastState.quadLayoutData.third) break;
                    broadcastState.quadLayoutData.third.data = {
                        ...broadcastState.quadLayoutData.third.data,
                        muted: key === 'volume' ? (!action[key]) : broadcastState.quadLayoutData.third.data.muted,
                        [key]: action[key]
                    };
                    break;
                case 4:
                    if (!broadcastState.quadLayoutData.fourth) break;
                    broadcastState.quadLayoutData.fourth.data = {
                        ...broadcastState.quadLayoutData.fourth.data,
                        muted: key === 'volume' ? (!action[key]) : broadcastState.quadLayoutData.fourth.data.muted,
                        [key]: action[key]
                    };
                    break;
                default:
                    if (!broadcastState.soloLayoutData.content) break;
                    broadcastState.soloLayoutData.content.data = {
                        ...broadcastState.soloLayoutData.content.data,
                        muted: key === 'volume' ? (!action[key]) : broadcastState.soloLayoutData.content.data.muted,
                        [key]: action[key]
                    };
                    break;
            }
            return {
                ...state,
                broadcastState
            };
        case 'MOVE_SCREEN_CONTENT':
            broadcastState = {
                ...state.broadcastState,
                quadLayoutData: {
                    ...state.broadcastState.quadLayoutData
                },
                soloLayoutData: {
                    ...state.broadcastState.soloLayoutData
                }
            };

            let from = convertScreenToProperty(action.from),
                to = convertScreenToProperty(action.to),
                tmp = null;

            if (from && to) {
                if (action.from === 'SOLO') {
                    tmp = !broadcastState.quadLayoutData[to] ? null : {...broadcastState.quadLayoutData[to]};
                    broadcastState.quadLayoutData[to] = {...broadcastState.soloLayoutData.content};
                    broadcastState.soloLayoutData.content = tmp;
                } else if (action.to === 'SOLO') {
                    tmp = !broadcastState.soloLayoutData.content ? null : {...broadcastState.soloLayoutData.content};
                    broadcastState.soloLayoutData.content = {...broadcastState.quadLayoutData[from]};
                    broadcastState.quadLayoutData[from] = tmp;
                } else {
                    tmp = !broadcastState.quadLayoutData[to] ? null : {...broadcastState.quadLayoutData[to]};
                    broadcastState.quadLayoutData[to] = {...broadcastState.quadLayoutData[from]};
                    broadcastState.quadLayoutData[from] = tmp;
                }
            }

            state.socket.emit('broadcast change', {
                room: state.room,
                user: state.user,
                fullState: true,
                state: broadcastState
            });

            return {
                ...state,
                broadcastState
            };
        case 'UPDATE_STREAM_SETTINGS':
            let streamSettings = { ...state.broadcastState.streamSettings };

            if (action?.volume) {
                streamSettings.volume = action.volume;
            }

            if (action?.autoPlay) {
                streamSettings.autoPlay = action.autoPlay;
            }

            return {
                ...state,
                broadcastState: {
                    ...state.broadcastState,
                    streamSettings
                }
            };
        case 'SET_MASTER_VOLUME':
            state.socket.emit('set master volume', {
                room: state.room,
                user: state.user,
                volume: action.volume
            });

            return {
                ...state,
                broadcastState: {
                    ...state.broadcastState,
                    soloLayoutData: {
                        ...state.soloLayoutData,
                        content: !!state.broadcastState.soloLayoutData.content ? { ...state.broadcastState.soloLayoutData.content, volume: action.volume, muted: !action.volume } : null
                    },
                    quadLayoutData: {
                        ...state.quadLayoutData,
                        first: !!state.broadcastState.quadLayoutData.first ? { ...state.broadcastState.quadLayoutData.first, volume: action.volume, muted: !action.volume } : null,
                        second: !!state.broadcastState.quadLayoutData.second ? { ...state.broadcastState.quadLayoutData.second, volume: action.volume, muted: !action.volume } : null,
                        third: !!state.broadcastState.quadLayoutData.third ? { ...state.broadcastState.quadLayoutData.third, volume: action.volume, muted: !action.volume } : null,
                        fourth: !!state.broadcastState.quadLayoutData.fourth ? { ...state.broadcastState.quadLayoutData.fourth, volume: action.volume, muted: !action.volume } : null,
                    },
                    streamSettings: {
                        ...state.broadcastState.streamSettings,
                        volume: action.volume
                    }
                }
            };
        case 'SET_SCREEN_VOLUME':
            state.socket.emit('set volume', {
                room: state.room,
                user: state.user,
                screen: action.screen,
                volume: action.volume
            });

            return {
                ...state,
                broadcastState: {
                    ...state.broadcastState,
                    soloLayoutData: {
                        ...state.soloLayoutData,
                        content: action.screen === 0 ? {
                            ...state.broadcastState.soloLayoutData.content,
                            data: {
                                ...state.broadcastState.soloLayoutData.content.data,
                                volume: action.volume,
                                muted: !action.volume
                            }
                        } : state.broadcastState.soloLayoutData.content
                    },
                    quadLayoutData: {
                        ...state.quadLayoutData,
                        first: action.screen === 1 ? {
                            ...state.broadcastState.quadLayoutData.first,
                            data: {
                                ...state.broadcastState.quadLayoutData.first.data,
                                volume: action.volume,
                                muted: !action.volume
                            }
                        } : state.broadcastState.quadLayoutData.first,
                        second: action.screen === 2 ? {
                            ...state.broadcastState.quadLayoutData.second,
                            data: {
                                ...state.broadcastState.quadLayoutData.second.data,
                                volume: action.volume,
                                muted: !action.volume
                            }
                        } : state.broadcastState.quadLayoutData.second,
                        third: action.screen === 3 ? {
                            ...state.broadcastState.quadLayoutData.third,
                            data: {
                                ...state.broadcastState.quadLayoutData.third.data,
                                volume: action.volume,
                                muted: !action.volume
                            }
                        } : state.broadcastState.quadLayoutData.third,
                        fourth: action.screen === 4 ? {
                            ...state.broadcastState.quadLayoutData.fourth,
                            data: {
                                ...state.broadcastState.quadLayoutData.fourth.data,
                                volume: action.volume,
                                muted: !action.volume
                            }
                        } : state.broadcastState.quadLayoutData.fourth
                    }
                }
            };
        case 'SET_SCREEN_CONTENT':
            state.socket.emit('broadcast change', {
                room: state.room,
                user: state.user,
                screen: action.screen,
                data: action.data
            });

            return {
                ...state,
                broadcastState: {
                    ...state.broadcastState,
                    soloLayoutData: {
                        ...state.soloLayoutData,
                        content: action.screen === 'SOLO' ? action.data : state.broadcastState.soloLayoutData.content
                    },
                    media: {
                        ...state.media,
                        content: action.screen === 'MEDIA' ? action.data : state.broadcastState.media.content
                    },
                    quadLayoutData: {
                        ...state.quadLayoutData,
                        first: action.screen === 'QUAD1' ? action.data : state.broadcastState.quadLayoutData.first,
                        second: action.screen === 'QUAD2' ? action.data : state.broadcastState.quadLayoutData.second,
                        third: action.screen === 'QUAD3' ? action.data : state.broadcastState.quadLayoutData.third,
                        fourth: action.screen === 'QUAD4' ? action.data : state.broadcastState.quadLayoutData.fourth
                    }
                }
            };
        case 'SET_SUB_VIEW':
            let subview = action.view;
            return {
                ...state,
                subview
            };
        case 'DELETE_SELECTED_ITEMS':
            let deletedItems = Object.values(state.items).filter(item => state.selectedItems.indexOf(item) !== -1);
            let keptItems = Object.values(state.items).filter(item => state.selectedItems.indexOf(item) === -1);

            state.socket.emit('delete entities', {
                room: state.room,
                user: state.user,
                deletedItems
            });

            return {
                ...state,
                items: arrayToObject(keptItems, 'id'),
                visibleItems: itemFilter(keptItems, state.folder, state.itemSettings, state.textFilter, state.liveTwitchStreams),
                selectedItems: []
            };
        case 'SORT':
            let iS = {
                ...state.itemSettings,
                sort: action.sort
            };
            return {
                ...state,
                visibleItems: itemFilter(state.visibleItems, state.folder, iS, state.textFilter, state.liveTwitchStreams),
                itemSettings: iS
            };
        case 'HIDE_DIALOG':
            return {
                ...state,
                dialog: null
            };
        // this one is for the automated bot message. remove it later.
        case 'ADD_MESSAGE':
            let messages = [...state.messages, action.message];
            return {
                ...state,
                messages,
                unreadMessages: state.messages.filter(message => !state.lastChatMessageRead || message.date > state.lastChatMessageRead).length
            };
        case 'TOGGLE_CHAT':
            return {
                ...state,
                unreadMessages: 0, // will need to be changed in the future... this assumes that we're leaving or entering the chat view, which may not always be the case
                lastChatMessageRead: moment(),
                showChat: !state.showChat
            }
        case 'SET_VIEW':
            let view = action.view;
            return {
                ...state,
                view
            };
        case 'CHANGE_FOLDER':
            let folder = action.folder;
            return {
                ...state,
                folder,
                visibleItems: itemFilter(Object.values(state.items), folder, state.itemSettings, state.textFilter, state.liveTwitchStreams)
            };
        case 'TOGGLE_FILTER_SETTING':
            let itemSettings = {...state.itemSettings},
                idx = itemSettings.filter.indexOf(action.entityType);

            if (idx >= 0) {
                itemSettings.filter.splice(idx, 1);
            } else {
                itemSettings.filter.push(action.entityType);
            }

            return {
                ...state,
                itemSettings,
                visibleItems: itemFilter(Object.values(state.items), state.folder, itemSettings, state.textFilter, state.liveTwitchStreams)
            };
        case 'SET_BROADCAST_STATE':
            broadcastState = action.broadcastState;
            return {
                ...state,
                broadcastState
            };
        case 'SET_SELF_USER_ROLE':
            return {
                ...state,
                user: state.room === action.room && state.user.id === action.user.id ? {
                    ...state.user,
                    role: action.role
                } : {
                    ...state.user
                }
            };
        case 'SET_ROOM_STATE':
            return {
                ...state,
                broadcastState: {
                    ...action.roomState.broadcastState
                },
                connectedUsers: {
                    ...action.roomState.users
                },
                messages: [
                    ...action.roomState.chatMessages
                ]
            };
        case 'SET_ITEM_LIST':
            items = {
                ...action.items
            };
            return {
                ...state,
                items,
                visibleItems: itemFilter(Object.values(items), state.folder, state.itemSettings, state.textFilter, state.liveTwitchStreams)
            };
        case 'REFRESH_VISIBLE_ITEMS':
            return {
                ...state,
                visibleItems: itemFilter(Object.values(state.items), state.folder, state.itemSettings, state.textFilter, state.liveTwitchStreams)
            };
        case 'SET_TEXT_FILTER':
            return {
                ...state,
                textFilter: action.text || ''
            };
        case 'NUKE_ROOM':
            state.socket.emit('nuke room', {
                room: action.room,
                user: state.user
            });

            return {
                ...state
            };
        case 'SET_ROOM':
            state.socket.emit('join room', {
                room: action.room,
                user: {
                    ...state.user,
                    broadcaster: action.broadcaster
                }
            });

            room = action.room;

            window.localStorage.setItem('pursuit-config', JSON.stringify({
                user: state.user,
                room: action.room,
                modified: moment()
            }));

            return {
                ...state,
                room
            };
        case 'SET_USER':
            let localStorage = window.localStorage.getItem('pursuit-config') ? JSON.parse(window.localStorage.getItem('pursuit-config')) : {};

            window.localStorage.setItem('pursuit-config', JSON.stringify({
                user: action.user,
                room: localStorage.room || null,
                modified: moment()
            }));

            return {
                ...state,
                user: action.user
            };
        case 'TOGGLE_AUTO_PLAY':
            return {
                ...state,
                broadcastState: {
                    ...state.broadcastState,
                    streamSettings: {
                        ...state.broadcastState.streamSettings,
                        autoPlay: action.autoPlay
                    }
                }
            };
        case 'ADMIN_SET_ROLE':
            user = {
                ...state.user,
                role: action.role
            };

            return {
                ...state,
                user
            };
        case 'SET_LIVE_STATUS':
            return {
                ...state,
                liveTwitchStreams: action.data
            };
        default:
            return state;
    }
};

export default mainReducer;