import React from 'react';
import {connect} from 'react-redux';
import View from "./View";
import Role from "./Role";
import ObserverSubView from "./ObserverSubView";
import Roles from "./Roles";

class SubViews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderView() {
        switch (this.props.subview) {
            case View.PRIMARY:
                return this.renderPrimaryViewByRole();
            case View.ROLES:
                return <Roles />;
            default:
                return null;
        }
    }

    renderPrimaryViewByRole() {
        if (!this.props.user) {
            return null;
        }

        return this.props.user?.role === Role.BROADCASTER || this.props.user?.role === Role.SUPER_USER ? (
            <div className="sub-view broadcast">
                <div style={{ color: '#999', textAlign: 'center', fontStyle: 'italic'}}>
                    One day, there will be things here!
                </div>
            </div>
        ) : (
            <ObserverSubView/>
        );
    }

    render() {
        return (
            <div className="sub-view-container">
                {this.renderView()}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        subview: state.subview,
        user: state.user
    };
};

export default connect(mapStateToProps)(SubViews);