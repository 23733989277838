import React from 'react';

class Logout extends React.Component {
    constructor(props) {
        super(props);
    }

    logout() {
        window.localStorage.removeItem('pursuit-config');
        setTimeout(() => {
            window.location.reload();
        }, 1);
    }

    render() {
        return (
            <div className="logout" title="Log out" onClick={() => this.logout()}>
                <i className="fas fa-sign-out-alt" />
            </div>
        );
    }
}

export default Logout;