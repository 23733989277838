import React from 'react';

class Pending extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dots: 1
        };
        this.interval = null;
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                dots: (this.state.dots % 4) + 1
            });
        }, 500);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderDots() {
        let dots = [];

        for (let i = 0; i < this.state.dots; i++) {
            dots.push(i);
        }

        return dots.map(dot => <span>.</span>);
    }

    render() {
        return (
            <span className="pending">{this.renderDots()}</span>
        );
    }
}

export default Pending;