export default () => {
    return {
        media: {
            content: null
        },
        soloLayoutData: {
            content: null
        },
        quadLayoutData: {
            first: null,
            second: null,
            third: null,
            fourth: null
        },
        streamSettings: {
            quality: 'AUTO',
            autoPlay: true,
            volume: 0.5,
            streams: {}
        },
        overlay: {
            enabled: false,
            left: '50%',
            top: '50%',
            font: {
                size: 24,
                family: 'Arial',
                color: '#ffffff'
            }
        }
    };
};