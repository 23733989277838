import React from 'react';
import {connect} from 'react-redux';
import Entity from "./Entity";

class ObserverSubView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDescription: true
        };
    }

    renderObserverModes() {
        const {dispatch} = this.props;

        const modes = [2, 3, 4];

        const observerModes = modes.map(mode => {
            let classes = ['observer-mode'];

            if (this.props.observer.mode === mode) {
                classes.push('selected');
            }

            return (
                <div className={classes.join(' ')} onClick={() => dispatch({ type: 'SET_OBSERVER_MODE', mode })}>
                    {`${mode}x${mode}`}
                </div>
            )
        });

        return (
            <div className="observer-modes">
                {observerModes}
            </div>
        )
    }

    renderDescription() {
        if (!this.state.showDescription) {
            return null;
        }

        return (
            <div className="description" onClick={() => this.setState({ showDescription: false })} style={{ cursor: 'pointer' }}>
                <div>Observe up to {Math.pow(this.props.observer.mode, 2)} different streams in a {this.props.observer.mode}x{this.props.observer.mode} grid.</div>
                <div>Drag-and-drop streams into the sockets below to populate the grid.</div>
                <div style={{fontStyle: 'italic'}}>(Click to dismiss)</div>
            </div>
        );
    }

    onReceivingItem(e, slotKey) {
        const { dispatch } = this.props;
        const streamData = e.dataTransfer.getData('item-data') ? JSON.parse(e.dataTransfer.getData('item-data')) : null;
        const slotData = e.dataTransfer.getData('observer-slot-data') ? JSON.parse(e.dataTransfer.getData('observer-slot-data')) : null;

        if (streamData) {
            if ([Entity.STREAM].indexOf(streamData.type) === -1) {
                return alert('This type of content is not allowed in this screen.');
            }

            dispatch({
                type: 'SET_OBSERVER_SLOT',
                slot: slotKey,
                data: streamData
            });
        } else if (slotData) {
            dispatch({
                type: 'MOVE_OBSERVER_SLOT',
                to: slotKey,
                from: slotData.slot
            });
        }
    }

    renderContainer(slotKey) {
        return (
            <div onDragOver={(e) => e.preventDefault()}
                 onDragEnd={(e) => e.preventDefault()}
                 onDrop={(e) => this.onReceivingItem(e, slotKey)}
                 className="stream-container">
                {this.renderStream(slotKey)}
            </div>
        );
    }

    renderStream(slotKey) {
        let slot = this.props.observer.slots[slotKey];

        if (!slot) {
            return null;
        }

        return (
            <div draggable
                 onContextMenu={(e) => this.onContextMenu(e, slotKey)}
                 onDragStart={(e) => this.onDragStart(e, slotKey)}
                 className="stream">
                {slot?.data?.title}
            </div>
        );
    }

    onContextMenu(e, slotKey) {
        e.preventDefault();

        const {dispatch} = this.props;

        dispatch({
            type: 'SET_OBSERVER_SLOT',
            slot: slotKey,
            data: null
        });
    }

    onDragEnd(e) {
        e.preventDefault();
    }

    onDragStart(e, slotKey) {
        e.dataTransfer.setData('observer-slot-data', JSON.stringify({
            slot: slotKey
        }));

        e.dataTransfer.dropEffect = 'move';
    }

    renderStreamGrid() {
        let grid = [];

        for (let i = 1; i <= this.props.observer.mode; i++) {
            let gridLayer = [];

            for (let j = 1; j <= this.props.observer.mode; j++) {
                let slotKey = i + 'x' + j,
                    key = this.props.observer.mode + '-' + slotKey,
                    classes = ['stream-grid', 'ns'];

                if (!!this.props.observer.slots[slotKey]) {
                    classes.push('contains-item');
                }

                gridLayer.push(
                    <div className={classes.join(' ')} key={key}>
                        {this.renderContainer(slotKey)}
                    </div>
                );
            }

            grid.push(
                <div className="layer" key={this.props.observer.mode + '-' + i}>{gridLayer.map(grid => grid)}</div>
            );
        }

        return grid;
    }

    render() {
        return (
            <div className="observer sub-view">
                {this.renderObserverModes()}
                {this.renderDescription()}
                {this.renderStreamGrid()}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        observer: state.observer
    };
};


export default connect(mapStateToProps)(ObserverSubView);