let Role = {
    // can do some stuff
    STAFFER: 'STAFFER',
    // can do a lot of stuff
    PRODUCER: 'PRODUCER',
    // can do nothing
    OBSERVER: 'OBSERVER',
    // can do everything
    SUPER_USER: 'SUPER USER',
    // can do anything that pertains to the active broadcast
    BROADCASTER: 'BROADCASTER',
    // can do everything within the context of the current room
    ADMINISTRATOR: 'ADMINISTRATOR'
};

export default Role;