import React from 'react';
import {connect} from 'react-redux';

import Entity from "./Entity";
import Order from "./order.js";

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: Order.DESC
        };
    }

    showFilterDialog() {
        const {dispatch} = this.props;

        dispatch({
            type: 'SHOW_DIALOG',
            dialog: 'ITEM_FILTER'
        });
    }

    showFolderDialog() {
        const {dispatch} = this.props;

        dispatch({
            type: 'SHOW_DIALOG',
            dialog: 'FOLDER_PATH'
        });
    }

    renderFilterSettings() {
        if (this.props.itemSettings.filter.length === 4) {
            return 'All';
        } else if (this.props.itemSettings.filter.length === 0) {
            return 'None';
        }

        let included = [];

        if (this.props.itemSettings.filter.indexOf(Entity.STREAM) >= 0) {
            included.push('Streams');
        }

        if (this.props.itemSettings.filter.indexOf(Entity.CLIP) >= 0) {
            included.push('Clips');
        }

        if (this.props.itemSettings.filter.indexOf(Entity.MEDIA) >= 0) {
            included.push('Media');
        }

        if (this.props.itemSettings.filter.indexOf(Entity.EXTERNAL) >= 0) {
            included.push('Externals');
        }

        return included.join(' & ');
    }

    renderFolder() {
        if (this.props.folder === null || this.props.folder === '/') {
            return (
                <span className="folder">
                    <span className="separator">/</span>
                    <span>root</span>
                </span>
            );
        }

        let folders = this.props.folder.slice(1).split('/');

        let folder = folders.map(folder => {
            return [
                <span className="separator">/</span>,
                <span>{folder}</span>
            ];
        })

        return (
            <span className="folder">{folder}</span>
        );
    }

    toggleSortOrder() {
        const {dispatch} = this.props;

        dispatch({
            type: 'SORT',
            sort: {
                key: this.props.itemSettings.sort.key,
                order: this.props.itemSettings.sort.order === Order.ASC ? Order.DESC : Order.ASC
            }
        });
    }

    render() {
        let directionClasses = ['direction', this.props.itemSettings.sort.order.toLowerCase()];

        return (
            <div className="filter ns">
                <div className="filter-box" onClick={() => this.showFolderDialog()}>
                    <span>Folder</span>
                    <span>{this.renderFolder()}</span>
                </div>
                <div className="filter-box" onClick={() => this.showFilterDialog()}>
                    <span>Show</span>
                    <span>{this.renderFilterSettings()}</span>
                </div>
                <div className="filter-box" onClick={() => this.toggleSortOrder()}>
                    <span>Order</span>
                    <span>
                        <span>{this.props.itemSettings.sort.key}</span>
                        <span className={directionClasses.join(' ')}>{this.props.itemSettings.sort.order}</span>
                    </span>
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        itemSettings: state.itemSettings,
        folder: state.folder
    };
};

export default connect(mapStateToProps)(Filter);