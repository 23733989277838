import Order from './order.js';
import Entity from "./Entity.js";
import {isLive} from "./twitchUtils.js";

let sortItems = (a, b, sort) => {
    if (sort.order === Order.ASC) return a[sort.key] - b[sort.key];
    else if (sort.order === Order.DESC) return b[sort.key] - a[sort.key];
    else return 0;
};

let itemFilter = (items, folder, settings, text, liveTwitchStreams) => {
    let filteredItems = items.filter(item => {
        return item.folder === folder && settings.filter.indexOf(item.type) >= 0 && (!text.length || item.title.toLowerCase().indexOf(text.toLowerCase()) >= 0);
    });

    filteredItems.sort((a, b) => sortItems(a, b, settings.sort));

    return [
        ...filteredItems.map(item => {
            // Non-streams should be unmodified.
            if (item.type !== Entity.STREAM) {
                return item;
            }

            // Add 'live' property to streams.
            item.live = isLive(item, liveTwitchStreams);

            return item;
        })
    ];
};

export default itemFilter;