import React from 'react';
import {connect} from 'react-redux';

import Role from "./Role";

class Nuke extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usersInRoom: 1
        };
    }

    nukeRoom() {
        const {dispatch, room, user} = this.props;

        console.log(user);

        if (user.role !== Role.ADMINISTRATOR && user.role !== Role.SUPER_USER) {
            alert("Only administrators are allowed to nuke broadcast settings.")
            return;
        }

        dispatch({
            type: 'NUKE_ROOM',
            user,
            room
        });
    }

    render() {
        if (!this.props.user)
            return null;

        if (this.props.user.role !== Role.ADMINISTRATOR && this.props.user.role !== Role.SUPER_USER)
            return null;

        return (
            <div className="nuke" onClick={() => this.nukeRoom()}>
                <i className="fas fa-radiation" title={`Nuke the current broadcast settings. Only to be used in case of emergencies.`} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room
    };
};

export default connect(mapStateToProps)(Nuke);