import React from 'react';

class Settings extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="settings">
                <i className="fas fa-cog" title="Not implemented" />
            </div>
        );
    }
}

export default Settings;