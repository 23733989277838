import React from 'react';
import {connect} from 'react-redux';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onClick(e) {
        const {dispatch} = this.props;

        dispatch({
            type: 'SET_VIEW',
            view: null
        });
    }

    render() {
        return (
            <div onClick={(e) => this.onClick(e)}>
                <i className="fas fa-home" title={'Return to the main view'} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        view: state.view
    };
}

export default connect(mapStateToProps)(Home);