import React from 'react';
import {connect} from 'react-redux';
import DialogBar from "./DialogBar";
import Settings from "./Settings";
import Users from "./Users";
import Messages from "./Messages";
import ContentContainer from "./ContentContainer";
import Layouts from "./Layouts";
import ItemList from "./ItemList";
import Items from "./Items";
import Filter from "./Filter";
import Home from "./Home";
import ItemActions from "./ItemActions";
import Chat from "./Chat";
import ViewActivator from "./ViewActivator";
import SubViewToggle from "./SubViewToggle";
import SubViews from "./SubViews";
import ScreenOverlay from "./ScreenOverlay";
import Logout from "./Logout";

import Entity from "./Entity";
import TextFilter from "./TextFilter";
import Nuke from "./Nuke";

class ProductionInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="production-interface">
                <DialogBar>
                    <Home />
                    <Messages />
                    <Users />
                    <Nuke />
                    <Settings />
                    <Logout />
                </DialogBar>
                <ContentContainer>
                    <Filter />
                    <TextFilter />
                    <ItemList>
                        <Items type={Entity.EXTERNAL} />
                        <Items type={Entity.MEDIA} />
                        <Items type={Entity.STREAM} />
                        <Items type={Entity.CLIP} />
                    </ItemList>
                    <ItemActions />

                    <Layouts />
                    <ScreenOverlay />

                    <Chat />

                    <SubViewToggle />
                    <SubViews />
                    <ViewActivator />
                </ContentContainer>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        view: state.view,
        room: state.room
    };
};

export default connect(mapStateToProps)(ProductionInterface);