import React from 'react';

class DialogBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ open: true });
        }, 1);
    }

    render() {
        let classes = ['dialog-bar'];

        if (this.state.open) {
            classes.push('open');
        }

        return (
            <div className={classes.join(' ')}>
                {this.props.children}
            </div>
        );
    }
}

export default DialogBar;