import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import ChatMessage from "./ChatMessage";

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // Will need an interval refresher permanently for timestamps to refresh automatically.

        setInterval(() => {
            this.setState({
                updated: new Date().getTime()
            });
        }, 60000);

        this.scrolledToBottom = true;

        this.scrollListenerFn = (e) => {
            this.scrolledToBottom = this.chatElem.scrollTop === (this.chatElem.scrollHeight - this.chatElem.offsetHeight);
        };
    }

    componentDidMount() {
        this.chatElem.addEventListener('scroll', this.scrollListenerFn);
    }

    componentWillUnmount() {
        this.chatElem.removeEventListener('scroll', this.scrollListenerFn);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.showChat && this.props.showChat) {
            this.textareaElem.focus();
            this.chatElem.scrollTo(0, this.chatElem.scrollHeight);
        }

        if (this.scrolledToBottom) {
            this.chatElem.scrollTo(0, this.chatElem.scrollHeight);
        }
    }

    onKeyDown(e) {
        const content = this.textareaElem.value || '';

        if (content.trim().length > 0 && (e.code === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey) {
            e.preventDefault();

            this.scrolledToBottom = true;

            this.props.socket.emit('chat message', {
                user: this.props.user,
                room: this.props.room,
                content,
                date: moment()
            });

            this.textareaElem.value = '';
        }
    }

    render() {
        let classes = ['chat'];

        if (this.props.showChat) {
            classes.push('open');
        }

        let previousAvatar = null;

        return (
            <div className={classes.join(' ')}>
                <div className="chat-messages" ref={elem => this.chatElem = elem}>
                    <div className="messages-wrapper">
                        {this.props.messages.map((message, idx) => {
                            const nextMessage = this.props.messages.length > idx + 1 ? this.props.messages[idx + 1] : null;
                            const firstInChain = previousAvatar !== message.avatar;
                            const lastInChain = nextMessage === null || nextMessage.author !== message.author;

                            const m = (
                                <ChatMessage
                                    key={idx}
                                    message={message}
                                    firstInChain={firstInChain}
                                    lastInChain={lastInChain}
                                />
                            );

                            previousAvatar = message.avatar;
                            return m;
                        })}
                    </div>
                </div>
                <div className="chat-input-container">
                    <textarea ref={elem => this.textareaElem = elem} onKeyDown={(e) => this.onKeyDown(e)}/>
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room,
        messages: state.messages,
        showChat: state.showChat,
        socket: state.socket
    };
};

export default connect(mapStateToProps)(Chat);