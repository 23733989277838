import React from 'react';
import {connect} from 'react-redux';

import Entity from "./Entity";
import Role from "./Role";

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPlaying: false,
            askQuestionOnRightClickDelete: true
        };

        this.rangeElem = null;
        this.queuedEvent = null;
        this.slider = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let previousContent = this.getContentForScreen(prevProps.screen, prevProps);
        let content = this.getContentForScreen(this.props.screen);

        if (!previousContent || !content) return;

        if (this.rangeElem && content?.data?.volume !== null) {
            this.rangeElem.value = content?.data?.volume;
        }
    }

    getContentForScreen(screen, context = this.props) {
        let key = null;

        if (screen === 1) key = 'first';
        else if (screen === 2) key = 'second';
        else if (screen === 3) key = 'third';
        else if (screen === 4) key = 'fourth';

        // console.log({...context});

        if (screen === 0) {
            return context.broadcastState.soloLayoutData.content;
        } else if (screen === null) {
            return context.broadcastState.media.content;
        } else {
            return context.broadcastState.quadLayoutData[key];
        }
    }

    onContextMenu(e) {
        e.preventDefault();

        const {dispatch} = this.props;

        // Maybe a bit too extreme to remove on right-click...
        /*
        dispatch({
            type: 'SET_SCREEN_CONTENT',
            screen: 'QUAD' + this.props.screen,
            data: null
        });
         */
    }

    onDragStart(e) {
        if ([Role.ADMINISTRATOR, Role.BROADCASTER, Role.SUPER_USER].indexOf(this.props.user?.role) === -1) {
            return null;
        }

        e.dataTransfer.setData('screen-data', JSON.stringify({
            screen: this.props.screen
        }));

        e.dataTransfer.dropEffect = 'move';
    }

    onDragEnd(e, entity) {
        e.preventDefault();
    }

    onClearScreen(e) {
        const { dispatch } = this.props;

        dispatch({
            type: 'SET_SCREEN_CONTENT',
            screen: this.props.screen === null ? 'MEDIA' : (!this.props.screen ? 'SOLO' : 'QUAD' + this.props.screen),
            data: null
        });
    }

    renderValue() {
        let content = this.getContentForScreen(this.props.screen);

        if (!content) {
            return null;
        }

        let classes = ['contained-item'];

        if (content?.type) {
            classes.push(content?.type.toLowerCase());
        }

        return (
            <span className={classes.join(' ')}
                  draggable
                  onContextMenu={(e) => this.onContextMenu(e)}
                  onDragStart={(e) => this.onDragStart(e)}
                  onDragEnd={(e) => this.onDragEnd(e)}
            >
                <span className="inner-box ns">
                    <span className="content">{content?.data?.title}</span>
                    {this.renderTopActions()}
                    {this.renderBottomActions()}
                </span>
            </span>
        );
    }

    toggleFullscreen(e) {
        if (!this.props.screen) {
            return;
        }

        let content = this.getContentForScreen(this.props.screen);

        let fullscreen = !(content?.data?.fullscreen);

        this.props.socket.emit('toggle fullscreen', {
            screen: this.props.screen,
            room: this.props.room,
            fullscreen
        });
    }

    toggleMute(e) {
        let content = this.getContentForScreen(this.props.screen);

        let muted = !(content?.data?.muted);

        this.props.socket.emit('mute screen', {
            screen: this.props.screen,
            room: this.props.room,
            muted
        });
    }

    togglePlayPause(e) {
        return;
    }

    renderTopActions() {
        let actions = [];

        if ([Role.ADMINISTRATOR, Role.BROADCASTER, Role.SUPER_USER].indexOf(this.props.user?.role) === -1) {
            return null;
        }

        let content = this.getContentForScreen(this.props.screen);

        if (content?.type === Entity.STREAM || content?.type === Entity.CLIP) {
            actions.push(
                <span className="top actions">
                    <i className={['fas', content?.data?.muted ? 'fa-volume-mute' : 'fa-volume-up'].join(' ')} onClick={(e) => this.toggleMute(e)} />
                    <i className={['fas', 'fa-times'].join(' ')}  onClick={(e) => this.onClearScreen(e)} />
                </span>
            );
        } else {
            actions.push(
                <span className="top actions">
                    <i />
                    <i />
                    <i className={['fas', 'fa-times'].join(' ')}  onClick={(e) => this.onClearScreen(e)} />
                </span>
            );
        }

        return actions;
    }

    renderBottomActions() {
        const {dispatch} = this.props;

        if ([Role.ADMINISTRATOR, Role.BROADCASTER, Role.SUPER_USER].indexOf(this.props.user?.role) === -1) {
            return null;
        }

        let content = this.getContentForScreen(this.props.screen);

        return (
            <span className="bottom actions">
                {true ? <i /> : <i className={['fas', content?.playing ? 'fa-pause': 'fa-play'].join(' ')} onClick={(e) => this.togglePlayPause(e)} />}
                <span className="slider" style={content?.type === Entity.EXTERNAL || content?.type === Entity.MEDIA ? {display: 'none'} : null}>
                    <span className="volume-slider">
                        <input type="range"
                               ref={elem => this.rangeElem = elem}
                               draggable={true}
                               min="0"
                               max="1"
                               defaultValue={content?.data?.volume !== null ? content?.data?.volume : this.props.broadcastState.streamSettings.volume}
                               step="0.01"
                               onChange={(e) =>{
                                   clearTimeout(this.queuedEvent);
                                   this.queuedEvent = setTimeout(() => {
                                       dispatch({
                                           type: 'SET_SCREEN_VOLUME',
                                           screen: this.props.screen,
                                           volume: e.target.value ? parseFloat(e.target.value) : 0
                                       });
                                   }, 250);
                               }}
                               onInput={(e) => {
                                   clearTimeout(this.queuedEvent);
                                   this.queuedEvent = setTimeout(() => {
                                       dispatch({
                                           type: 'SET_SCREEN_VOLUME',
                                           screen: this.props.screen,
                                           volume: e.currentTarget.value ? parseFloat(e.currentTarget.value) : 0
                                       });
                                   }, 250);
                               }}
                               onDragStart={(e) => this.prevent(e)}
                        />
                    </span>
                </span>
                {this.props.screen ? <i className={['fas', content?.data?.fullscreen ? 'fa-compress' : 'fa-expand'].join(' ')} onClick={(e) => this.toggleFullscreen(e)}/> : <i />}
            </span>
        );
    }

    prevent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    onReceivingItem(e) {
        if ([Role.ADMINISTRATOR, Role.BROADCASTER, Role.SUPER_USER].indexOf(this.props.user?.role) === -1) {
            return null;
        }

        const { dispatch } = this.props;

        const itemData = e.dataTransfer.getData('item-data') ? JSON.parse(e.dataTransfer.getData('item-data')) : null;
        const screenData = e.dataTransfer.getData('screen-data') ? JSON.parse(e.dataTransfer.getData('screen-data')) : null;

        if (itemData) {
            if (this.props.allowedContent.indexOf(itemData.type) === -1) {
                return alert('This type of content is not allowed in this screen.');
            }

            dispatch({
                type: 'SET_SCREEN_CONTENT',
                screen: this.props.screen === null ? 'MEDIA' : (!this.props.screen ? 'SOLO' : 'QUAD' + this.props.screen),
                data: itemData
            });
        } else if (screenData) {
            if (screenData.screen === null || this.props.screen === null) {
                return alert('This feature is not enabled. To modify media data, please drag the content directly from the list on your left.');
            }

            dispatch({
                type: 'MOVE_SCREEN_CONTENT',
                from: !screenData.screen ? 'SOLO' : 'QUAD' + screenData.screen,
                to: !this.props.screen ? 'SOLO' : 'QUAD' + this.props.screen
            });
        }
    }

    render() {
        let classes = ['screen'];

        switch(this.props.screen) {
            case null:
                classes.push('static');
                break;
            case 0:
                classes.push('solo');
                break;
            case 1:
                classes.push('one');
                break;
            case 2:
                classes.push('two');
                break;
            case 3:
                classes.push('three');
                break;
            case 4:
                classes.push('four');
                break;
            default:
                break;
        }

        if (this.getContentForScreen(this.props.screen)) {
            classes.push('contains-item');
        }

        return (
            <div onDragOver={(e) => e.preventDefault()}
                 onDragEnd={(e) => e.preventDefault()}
                 onDrop={(e) => this.onReceivingItem(e)}
                 className={classes.join(' ')}
            >{this.renderValue()}</div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room,
        socket: state.socket,
        broadcastState: state.broadcastState
    };
};

export default connect(mapStateToProps)(Screen);