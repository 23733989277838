import React from 'react';

class EmbedVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.elem = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.elem) return;
        this.elem.volume = this.props.volume;
    }

    componentDidMount() {
        if (!this.elem) return;
        this.elem.volume = this.props.volume;
    }

    render() {
        return (
            <video id={'video-' + this.props.screen}
                   ref={elem => this.elem = elem}
                   width={window.innerWidth / 2}
                   height={window.innerHeight / 2}
                   autoPlay={this.props.autoPlay}
                   muted={this.props.muted}
                   controls={this.props.fullscreen}
                   loop={true}>
                <source src={this.props.url} type="video/mp4" />
            </video>
        );
    }
}

export default EmbedVideo;