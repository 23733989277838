import React from 'react';
import {connect} from 'react-redux';

class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onClick(e) {
        const {dispatch} = this.props;

        dispatch({
            type: 'TOGGLE_CHAT'
        });
    }

    render() {
        let classes = ['messages'];

        if (this.props.unreadMessages > 0) {
            classes.unshift('unread');
        }

        return (
            <div className={classes.join(' ')} onClick={(e) => this.onClick(e)}>
                <i className="fas fa-comment" title={this.props.unreadMessages > 0 ? `You have ${this.props.unreadMessages} unread messages.` : 'Go to the chat view'} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        view: state.view,
        unreadMessages: state.unreadMessages
    };
};

export default connect(mapStateToProps)(Messages);