import React from 'react';
import {connect} from 'react-redux';

class ItemActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    removeSelectedItems() {
        const {dispatch} = this.props;

        const unseenSelectedItems = this.props.selectedItems.filter(item => this.props.visibleItems.indexOf(item) === -1).length,
            removalQuestion = unseenSelectedItems > 1 ?
                `You have ${unseenSelectedItems} selected items that are not currently visible due to your current filter.\n\nDo you want to proceed with removing your selected items?` :
                `You have ${unseenSelectedItems} selected item that is not currently visible due to your current filter.\n\nDo you want to proceed with removing the selected item?`


        if (unseenSelectedItems > 0 && !window.confirm(removalQuestion)) {
            return;
        }

        dispatch({
            type: 'DELETE_SELECTED_ITEMS'
        });
    }

    addItem() {
        const {dispatch} = this.props;

        dispatch({
            type: 'SHOW_DIALOG',
            dialog: 'ADD_ITEM'
        });
    }

    render() {
        return (
            <div className="item-actions ns">
                <div title="Add an item" onClick={() => this.addItem()}>
                    <i className="fas fa-plus" />
                </div>

                <div title="Add a batch of items (not implemented)">
                    <i className="fas fa-upload" />
                </div>

                <div title="Remove selected items" onClick={() => this.removeSelectedItems()}>
                    <i className="fas fa-trash" />
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        itemSettings: state.itemSettings,
        selectedItems: state.selectedItems,
        visibleItems: state.visibleItems,
    };
};

export default connect(mapStateToProps)(ItemActions);