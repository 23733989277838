import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import io from 'socket.io-client';

import View from "./View";
import Order from "./order";
import Entity from "./Entity";

import './index.css';

import App from './App';

import mainReducer from './reducers/main';

import reportWebVitals from '../../pursuit/src/reportWebVitals';

import getDefaultBroadcastState from './broadcastState';

let initialItemSettings = {
    filter: [
        Entity.STREAM,
        Entity.CLIP,
        Entity.EXTERNAL,
        Entity.MEDIA
    ],
    sort: {
        key: 'created',
        order: Order.DESC
    }
};

let defaultBroadcastState = getDefaultBroadcastState();

let localStorage = window.localStorage.getItem('pursuit-config') ?
    JSON.parse(window.localStorage.getItem('pursuit-config')) : {};

let deleteLocalStorage = !localStorage.modified ? false : moment().diff(localStorage.modified, 'hours') > 24;
if (deleteLocalStorage) {
    window.localStorage.removeItem('pursuit-config');
    localStorage = {};
}

let initialState = {
    user: localStorage.user || null,
    room: localStorage.room || null,
    authSetByLocalStorage: localStorage.user && localStorage.room,
    broadcastState: defaultBroadcastState,
    lastChatMessageRead: null,
    dialog: null,
    view: null,
    showChat: false,
    textFilter: '',
    liveTwitchStreams: null,
    observer: {
        mode: 3,
        slots: {}
    },
    subview: View.PRIMARY,
    folder: '/',
    folders: ['/vacation', '/taxes'],
    messages: [],
    unreadMessages: 0,
    connectedUsers: {},
    selectedItems: [],
    items: [],
    visibleItems: [],
    itemSettings: initialItemSettings,
    socket: io('https://pursuit-ws.poe-racing.com', {
        forceNew: true,
        upgrade: false,
        secure: true,
        transports: ['websocket']
    })
};

console.log({...initialState});

let store = createStore(mainReducer, initialState);

const init = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
