import React from 'react';
import {connect} from 'react-redux';

import Role from "./Role";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usersInRoom: 1
        };
    }

    toggleUserTypeIfSuperUser() {
        const {dispatch} = this.props;

        return;

        let role = this.props.role;

        if (this.props.user.name === 'Faderon') {
            role = this.props.user.role !== Role.BROADCASTER ? Role.BROADCASTER : Role.STAFFER;
        }

        dispatch({
            type: 'ADMIN_SET_ROLE',
            role
        });
    }

    render() {
        const builtUserString = this.state.usersInRoom === 1 ? `is one connected user`
            : `are ${this.state.usersInRoom || 'no'} connected users`;

        return (
            <div className="users" onClick={() => this.toggleUserTypeIfSuperUser()}>
                <i className="fas fa-users" title={`There ${builtUserString} in this room.`} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(Users);