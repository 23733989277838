import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import Role from "./Role";

class ViewActivator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    spawnBroadcastTab() {
        window.localStorage.setItem('pursuit-config', JSON.stringify({
            user: this.props.user,
            room: this.props.room,
            modified: moment()
        }));

        setTimeout(() => {
            window.open(window.location.href, '_broadcast');
        }, 1);
    }

    spawnObserverTab() {
        window.localStorage.setItem('pursuit-config', JSON.stringify({
            user: this.props.user,
            room: this.props.room,
            modified: moment(),
            observer: true,
            observing: [] // list of streams to observe
        }));

        setTimeout(() => {
            window.open(window.location.href, '_observer');
        }, 1);
    }

    renderButtonTextByRole() {
        switch (this.props.user?.role) {
            case Role.SUPER_USER:
            case Role.BROADCASTER:
                return (
                    <span>
                        &laquo; Initiate Broadcast &raquo;
                    </span>
                );
            default:
                return (
                    <span>
                        &laquo; Observe Streams &raquo;
                    </span>
                );
        }
    }

    renderActivator() {
        return (
            <span className="view-activator"
                  title={this.getTitleByRole()}
            >{this.renderButtonTextByRole()}</span>
        );
    }

    getTitleByRole() {
        switch (this.props.user?.role) {
            case Role.SUPER_USER:
            case Role.BROADCASTER:
                return 'Initiate the broadcast window';
            default:
                return 'Observe selected streams';
        }
    }

    getActivatorActionByRole() {
        switch (this.props.user?.role) {
            case Role.SUPER_USER:
            case Role.BROADCASTER:
                return this.spawnBroadcastTab();
            default:
                return this.spawnObserverTab();
        }
    }

    render() {
        return (
            <div className="view-activation"
                 onClick={(e) => this.getActivatorActionByRole()}>
                {this.renderActivator()}
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room
    }
};

export default connect(mapStateToProps)(ViewActivator);