import React from 'react';
import {connect} from 'react-redux';
import Role from "./Role";

class Roles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    isVisible(user) {
        if (this.props.user.role === Role.SUPER_USER) return true;

        if (this.props.user.role !== Role.SUPER_USER && user.role === Role.SUPER_USER) return false;

        return true;
    }

    makeAdmin(user) {
        user.role = 'ADMINISTRATOR';

        this.props.socket.emit('change user role', {
            user: this.props.user,
            room: this.props.room,
            targetUser: user
        });
    }

    userIsAdministrator(user) {
        return user.role === Role.SUPER_USER || user.role === Role.ADMINISTRATOR;
    }

    renderConnectedUsers() {
        let users = Object.values(this.props.connectedUsers);

        return users
            .filter(user => this.isVisible(user))
            .map((user, key) => {
                return (
                    <div className="user" key={key} style={{ opacity: user.role === Role.SUPER_USER ? 0.5 : 1 }}>
                        <div className="avatar">
                            <img className="ns" alt="Avatar" src={user.avatar} />
                        </div>
                        <div className="info">
                            <div className="name">
                                <span>{user.name}</span>
                                {user.broadcaster ? <span style={{ marginLeft: '.5em', fontWeight: 'normal' }}>(Broadcasting)</span> : null}
                            </div>
                            <div className="role">{user.role}</div>
                        </div>
                        <div className="actions">
                            {(this.userIsAdministrator(this.props.user)) && !this.userIsAdministrator(user) && !user.broadcaster ? <i className="fa fa-key" onClick={(e) => this.makeAdmin(user)} /> : null}
                        </div>
                    </div>
                );
            });
    }

    render() {
        return (
            <div className="connected-users">
                {this.renderConnectedUsers()}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room,
        subview: state.subview,
        socket: state.socket,
        connectedUsers: state.connectedUsers
    };
};

export default connect(mapStateToProps)(Roles);