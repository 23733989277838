import React from 'react';

class Dialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="dialog-container" onClick={this.props.onClose}>
                <div className="dialog" onClick={e => e.stopPropagation()}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Dialog;