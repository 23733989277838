import React from 'react';
import { TwitchEmbed } from "twitch-player";
import Quality from "./quality";

class TwitchPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.iframeContainer = null;
    }

    componentDidMount() {
        this.embed = new TwitchEmbed('screen-' + this.props.screen, {
            channel: this.extractTwitchHandle(this.props.stream) || null,
            video: this.props.clip || null,
            width: window.innerWidth / 2,
            height: window.innerHeight / 2,
            controls: false,
            parent: ['pursuit.poe-racing.com'],
            allowfullscreen: true,
            muted: this.props.muted,
            autoplay: this.props.autoPlay,
            layout: 'video'
        });

        this.previousQuality = null;

        this.embed.addEventListener('playing', () => {
            let availableQualities = this.embed.getPlayer().getQualities();
            let quality = this.getQuality(this.props.quality, availableQualities);

            // Removing this condition will most likely reintroduce stuttering because setQuality triggers a re-instantiation within the iframe.
            if (quality !== this.previousQuality) {
                this.embed.getPlayer().setQuality(quality);
            }

            this.embed.getPlayer().setMuted(this.props.muted);
            this.embed.getPlayer().setVolume(this.props.volume);
            this.previousQuality = quality;
        });
    }

    extractTwitchHandle(url) {
        if (!url) return url;

        let matches = url.match(/twitch.tv\/(\w+)/);
        if (matches) return matches[1];
        else return null;
    }

    getQuality(quality, availableQualities) {
        if (quality === Quality.LOW) {
            return availableQualities[availableQualities.length - 1]?.group;
        } else if (quality === Quality.MID) {
            return availableQualities[Math.floor(availableQualities.length / 2)]?.group;
        } else if (quality === Quality.HIGH) {
            return availableQualities[1]?.group;
        } else {
            return 'chunked';
        }
    }

    render() {
        let classes = ['stream-container'];

        if (this.props.fullscreen) {
            classes.push('fullscreen');
        }

        const height = Math.floor(window.innerHeight / 2);
        const width = Math.floor(height * (16 / 9));

        // screen: 1-4 are the sub-screens, and screen: 0 is the fullscreen. Styles should not be applied to screen-0.
        return (
            <div className={classes.join(' ')}>
                <div ref={(elem) => this.iframeContainer = elem}
                     className="stream"
                     style={this.props.screen && !this.props.fullscreen ? { width: width + 'px', height: height + 'px' } : null}>
                    <div id={'screen-' + this.props.screen} />
                    {this.props.children}
                </div>
                <div className="shroud" />
            </div>
        );
    }
}

export default TwitchPlayer;