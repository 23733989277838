import React from 'react';
import {connect} from 'react-redux';
import Entity from "./Entity";

// TODO: The color switcher triggers events way too often. Try to limit it with some kind of interval that checks for changes and only updates if needed.
class ScreenOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentBoxLocked: true
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    renderFontFamilyOptions() {
        const fonts = [
            'Arial',
            'Verdana',
            'Helvetica',
            'Tahoma',
            'Trebuchet MS',
            'Times New Roman',
            'Georgia',
            'Garamond',
            'Courier New',
            'Brush Script MT'
        ];

        return fonts.map((font, idx) => <option selected={this.props.broadcastState.overlay.font.family === font} key={idx} value={font}>{font}</option>);
    }

    renderFontSizeOptions() {
        const sizes = [
            12,
            18,
            24,
            30,
            38,
            46,
            60,
            80,
            100,
            128,
            192
        ];

        return sizes.map((size, idx) => <option selected={this.props.broadcastState.overlay.font.size === size} key={idx} value={size}>{size}</option>);
    }

    renderAffectedItemTypes() {
        const types = [
            Entity.STREAM,
            Entity.CLIP,
            Entity.EXTERNAL,
            'ALL_EXCEPT_MEDIA'
        ];

        return types.map((type, idx) => <option key={idx} value={type}>{this.translateAffectedItemType(type)}</option>);
    }

    translateAffectedItemType(type) {
        switch(type) {
            case Entity.STREAM: return 'Streams';
            case Entity.CLIP: return 'Clips';
            case Entity.EXTERNAL: return 'External Resources';
            case 'ALL_EXCEPT_MEDIA': return 'Non-Media';
            default: return 'Unknown';
        }
    }

    onUpdateOverlay() {
        this.props.socket.emit('update overlay', {
            user: this.props.user,
            room: this.props.room,
            overlay: {
                ...this.props.broadcastState.overlay,
                enabled: !this.props.broadcastState.overlay.enabled
            }
        });
    }

    renderOverlayPositionSetting() {
        if (!this.props.broadcastState.overlay.enabled) {
            return null;
        }

        return (
            <div>
                <label htmlFor="overlay-position">Position</label>
                <button id="overlay-position" onClick={() => this.setState({ contentBoxLocked: !this.state.contentBoxLocked })}>{this.state.contentBoxLocked ? 'Unlock' : 'Lock'}</button>
            </div>
        );
    }

    renderOverlayContentSetting() {
        if (!this.props.broadcastState.overlay.enabled) {
            return null;
        }

        return (
            <div>
                <label htmlFor="overlay-content">Content</label>
                <input disabled type="text" id="overlay-content" value="{title}" />
            </div>
        );
    }

    renderOverlayAffectedItemsSetting() {
        if (!this.props.broadcastState.overlay.enabled) {
            return null;
        }

        return (
            <div>
                <label htmlFor="overlay-affects">Affects</label>
                <select disabled id="overlay-affects">
                    {this.renderAffectedItemTypes()}
                </select>
            </div>
        );
    }

    hasFontSettingsChanged() {
        return parseInt(this.sizeElem.value) !== this.props.broadcastState.overlay.font.size ||
            this.familyElem.value !== this.props.broadcastState.overlay.font.family ||
            this.colorElem.value !== this.props.broadcastState.overlay.font.color;
    }

    onUpdateFontOptions(e) {
        if (!this.hasFontSettingsChanged()) {
            return;
        }

        let font = {
            size: parseInt(this.sizeElem.value),
            color: this.colorElem.value,
            family: this.familyElem.value
        };

        this.props.socket.emit('update overlay', {
            user: this.props.user,
            room: this.props.room,
            overlay: {
                ...this.props.broadcastState.overlay,
                font
            }
        });
    }

    renderOverlaySettings() {
        return (
            <div className="card">
                <h3>Overlay Settings</h3>
                <div>
                    <label htmlFor="overlay-active">Overlay</label>
                    <button id="overlay-active" onClick={() => this.onUpdateOverlay()}>{this.props.broadcastState.overlay.enabled ? 'Disable' : 'Enable'}</button>
                </div>
                {this.renderOverlayPositionSetting()}
                {this.renderOverlayAffectedItemsSetting()}
                {this.renderOverlayContentSetting()}
            </div>
        );
    }

    renderFontSettings() {
        if (!this.props.broadcastState.overlay.enabled) {
            return null;
        }

        return (
            <div className="card">
                <h3>Font Settings</h3>
                <div>
                    <label htmlFor="font-color">Color</label>
                    <input onInput={(e) => this.onUpdateFontOptions(e)}
                           onBlur={(e) => e.preventDefault()}
                           onFocus={(e) => e.preventDefault()}
                           ref={(elem) => this.colorElem = elem}
                           type="color"
                           id="font-color"
                           defaultValue={this.props.broadcastState.overlay.font.color}
                    />
                </div>
                <div>
                    <label htmlFor="font-size">Size</label>
                    <select id="font-size"
                            ref={(elem) => this.sizeElem = elem}
                            onFocus={(e) => e.preventDefault()}
                            onBlur={(e) => e.preventDefault()}
                            onChange={(e) => this.onUpdateFontOptions(e)}
                    >
                        {this.renderFontSizeOptions()}
                    </select>
                </div>
                <div>
                    <label htmlFor="font-family">Family</label>
                    <select id="font-family"
                            ref={(elem) => this.familyElem = elem}
                            onFocus={(e) => e.preventDefault()}
                            onBlur={(e) => e.preventDefault()}
                            onChange={(e) => this.onUpdateFontOptions(e)}
                    >
                        {this.renderFontFamilyOptions()}
                    </select>
                </div>
            </div>
        );
    }

    onMouseDown(e) {
        if (this.state.contentBoxLocked) {
            return null;
        }

        this.isDragging = true;
    }

    onMouseMove(e) {
        if (this.state.contentBoxLocked) {
            return null;
        }

        if (!this.isDragging) {
            return null;
        }

        let coordinates = this.containerElem.getBoundingClientRect(),
            containerWidth = 500,
            containerHeight = 300,
            contentBoxSize = 20 / 2;

        let posX = (parseFloat(e.clientX) - coordinates.left),
            posY = (parseFloat(e.clientY) - coordinates.top);

        this.placeholderElem.style.left = Math.max(Math.min(containerWidth - contentBoxSize, posX), contentBoxSize) + 'px';
        this.placeholderElem.style.top = Math.max(Math.min(containerHeight - contentBoxSize, posY), contentBoxSize) + 'px';
    }

    onEndDrag(e) {
        if (!this.isDragging) {
            return;
        }

        this.isDragging = false;

        let coordinates = this.containerElem.getBoundingClientRect();

        this.props.socket.emit('update overlay', {
            user: this.props.user,
            room: this.props.room,
            overlay: {
                ...this.props.broadcastState.overlay,
                left: ((100 * parseFloat(this.placeholderElem.style.left) / coordinates.width) || 0) + '%',
                top: ((100 * parseFloat(this.placeholderElem.style.top) / coordinates.height) || 0) + '%'
            }
        });
    }

    onGoBack(e) {
        const {dispatch} = this.props;

        dispatch({
            type: 'SET_VIEW',
            view: null
        });
    }

    renderAnchor() {
        if (!this.props.broadcastState.overlay.enabled) {
            return null;
        }

        let draggableClasses = ['anchor'];

        if (this.state.contentBoxLocked) {
            draggableClasses.push('locked');
        }

        return (
            <div className={draggableClasses.join(' ')}
                 title="Unlock and drag this anchor to reposition the overlay point of origin"
                 ref={(elem) => this.placeholderElem = elem}
                 onMouseDown={(e) => this.onMouseDown(e)}
                 onContextMenu={(e) => {
                     e.stopPropagation();
                     e.preventDefault();
                 }}
                 style={{
                     background: this.props.broadcastState.overlay.font.color,
                     left: this.props.broadcastState.overlay.left,
                     top: this.props.broadcastState.overlay.top
                 }}
            />
        )
    }

    render() {
        if (this.props.view !== 'SCREEN-OVERLAY') {
            return null;
        }

        let classes = ['screen-overlay'];

        return (
            <div className="screen-overlay-container" onMouseUp={(e) => this.onEndDrag(e)}>
                <div className={classes.join(' ')}>
                    <div className="panel">
                        <h1>Screen Overlay</h1>

                        <div className="screen ns"
                             ref={(elem) => this.containerElem = elem}
                             onMouseMove={(e) => this.onMouseMove(e)}
                        >
                            {this.renderAnchor()}
                        </div>

                        <div className="overlay-settings-container">
                            {this.renderOverlaySettings()}
                            {this.renderFontSettings()}
                        </div>

                        <div className="broadcast-settings">
                            <div className="edit-overlay">
                                <div className="title">
                                    <span>Front Page</span>
                                </div>
                                <div className="button">
                                    <button onClick={() => this.onGoBack()} title="Click to return to the main production interface.">
                                        Return
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        view: state.view,
        user: state.user,
        room: state.room,
        broadcastState: state.broadcastState,
        socket: state.socket
    }
};

export default connect(mapStateToProps)(ScreenOverlay);