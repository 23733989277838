import React from 'react';
import {connect} from 'react-redux';
import TwitchPlayer from "./TwitchPlayer";
import Entity from "./Entity";
import Quality from "./quality";
import EmbedVideo from "./EmbedVideo";
import ExternalContent from "./ExternalContent";

class MainBroadcast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.screenMedia = null;

        this.screenZero = null;
        this.screenOne = null;
        this.screenTwo = null;
        this.screenThree = null;
        this.screenFour = null;

        this.mousePosition = {x: null, y: null};

        this.onMouseMoveFn = (e) => {
            this.mousePosition = {
                x: e.pageX,
                y: e.pageY
            };
        };

        // KeyW = Toggle Fullscreen
        // KeyS = Toggle Sound
        this.onKeyPressFn = (e) => {
            console.log(e);

            // Consider implementing hot keys. Do note that the CTRL commands are VERY invasive.
            // Especially CTRL+W which closes a tab.
        };

        this.onResizeFn = (e) => {
            const height = Math.floor(window.innerHeight / 2);
            const width = Math.floor(height * (16 / 9));

            if (this.screenOne?.iframeContainer) {
                this.screenOne.iframeContainer.style.width = width + 'px';
                this.screenOne.iframeContainer.style.height = height + 'px';
            }

            if (this.screenTwo?.iframeContainer) {
                this.screenTwo.iframeContainer.style.width = width + 'px';
                this.screenTwo.iframeContainer.style.height = height + 'px';
            }

            if (this.screenThree?.iframeContainer) {
                this.screenThree.iframeContainer.style.width = width + 'px';
                this.screenThree.iframeContainer.style.height = height + 'px';
            }

            if (this.screenFour?.iframeContainer) {
                this.screenFour.iframeContainer.style.width = width + 'px';
                this.screenFour.iframeContainer.style.height = height + 'px';
            }
        };
    }

    determineScreenByMousePosition = () => {
        const horizontalBreakPoint = window.innerWidth / 2;
        const verticalBreakPoint = window.innerHeight / 2;

        const currentFullscreen = this.getCurrentFullscreenScreen();

        // Standard truthy check will omit 'null' and '0' (main fullscreen component, since it doesn't work with hot keys)
        if (currentFullscreen) {
            return currentFullscreen;
        }

        if (this.mousePosition.x < horizontalBreakPoint && this.mousePosition.x >= 0) {
            if (this.mousePosition.y < verticalBreakPoint && this.mousePosition.y >= 0) {
                return 1;
            } else if (this.mousePosition.y < window.innerHeight) {
                return 3;
            }
        } else {
            if (this.mousePosition.y < verticalBreakPoint && this.mousePosition.y >= 0) {
                return 2;
            } else if (this.mousePosition.y < window.innerHeight) {
                return 4;
            }
        }

        return null;
    }

    setRef(screen, elem) {
        switch(screen) {
            case null:
                this.screenMedia = elem;
                break;
            case 0:
                this.screenZero = elem;
                break;
            case 1:
                this.screenOne = elem;
                break;
            case 2:
                this.screenTwo = elem;
                break;
            case 3:
                this.screenThree = elem;
                break;
            case 4:
                this.screenFour = elem;
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch({
            type: 'SET_ROOM',
            broadcaster: true,
            room: this.props.room
        });

        this.setSocketEventHandlers();
        this.setKeyEventHandlers();
        this.setWindowResizeHandler();
        this.setMouseEventHandlers();
    }

    componentWillUnmount() {
        this.unsetSocketEventHandlers();
        this.unsetKeyEventHandlers();
        this.unsetWindowResizeHandler();
        this.unsetMouseEventHandlers();
    }

    unsetSocketEventHandlers() {
        this.props.socket.off('mute screen');
        this.props.socket.off('set quality');
        this.props.socket.off('set volume');
    }

    unsetKeyEventHandlers() {
        document.removeEventListener('keydown', this.onKeyPressFn);
    }

    setKeyEventHandlers() {
        document.addEventListener('keydown', this.onKeyPressFn);
    }

    unsetMouseEventHandlers() {
        document.removeEventListener("mousemove", this.onMouseMoveFn);
    }

    setMouseEventHandlers() {
        document.addEventListener("mousemove", this.onMouseMoveFn);
    }

    unsetWindowResizeHandler() {
        window.removeEventListener('resize', this.onResizeFn);
    }

    setWindowResizeHandler() {
        window.addEventListener('resize', this.onResizeFn);
    }

    setSocketEventHandlers() {
        const {dispatch} = this.props;

        this.props.socket.on('mute screen', (data) => {
            // mute toggle for clips
            let clip = document.getElementById('video-' + data.screen);
            if (clip) {
                clip.muted = data.muted;
            } else {
                // Mute toggle for twitch streams
                switch (data.screen) {
                    case 0:
                        this.screenZero?.embed?.getPlayer().setMuted(data.muted);
                        break;
                    case 1:
                        this.screenOne?.embed?.getPlayer().setMuted(data.muted);
                        break;
                    case 2:
                        this.screenTwo?.embed?.getPlayer().setMuted(data.muted);
                        break;
                    case 3:
                        this.screenThree?.embed?.getPlayer().setMuted(data.muted);
                        break;
                    case 4:
                        this.screenFour?.embed?.getPlayer().setMuted(data.muted);
                        break;
                    default:
                        break;
                }
            }
        });

        this.props.socket.on('set volume', (data) => {
            let clip = document.getElementById('video-' + data.screen);
            if (clip) {
                clip.volume = data.volume; // apparently setting volume to 0 does not mute the <video>... strange.
                clip.muted = !data.volume;
            } else {
                // Mute toggle for twitch streams
                switch (data.screen) {
                    case 0:
                        this.screenZero?.embed?.getPlayer().setVolume(data.volume);
                        this.screenZero?.embed?.getPlayer().setMuted(!data.volume);
                        break;
                    case 1:
                        this.screenOne?.embed?.getPlayer().setVolume(data.volume);
                        this.screenOne?.embed?.getPlayer().setMuted(!data.volume);
                        break;
                    case 2:
                        this.screenTwo?.embed?.getPlayer().setVolume(data.volume);
                        this.screenTwo?.embed?.getPlayer().setMuted(!data.volume);
                        break;
                    case 3:
                        this.screenThree?.embed?.getPlayer().setVolume(data.volume);
                        this.screenThree?.embed?.getPlayer().setMuted(!data.volume);
                        break;
                    case 4:
                        this.screenFour?.embed?.getPlayer().setVolume(data.volume);
                        this.screenFour?.embed?.getPlayer().setMuted(!data.volume);
                        break;
                    default:
                        break;
                }
            }
        });

        this.props.socket.on('set quality', (data) => {
            [0, 1, 2, 3, 4].forEach((screen, idx) => {
                let clip = document.getElementById('video-' + screen),
                    qualities = null;
                if (!clip) {
                    // Mute toggle for twitch streams
                    switch (screen) {
                        case 0:
                            qualities = this.screenZero?.embed?.getPlayer().getQualities();
                            this.screenZero?.embed?.getPlayer().setQuality(this.getQuality(data.quality, qualities));
                            break;
                        case 1:
                            qualities = this.screenOne?.embed?.getPlayer().getQualities();
                            this.screenOne?.embed?.getPlayer().setQuality(this.getQuality(data.quality, qualities));
                            break;
                        case 2:
                            qualities = this.screenTwo?.embed?.getPlayer().getQualities();
                            this.screenTwo?.embed?.getPlayer().setQuality(this.getQuality(data.quality, qualities));
                            break;
                        case 3:
                            qualities = this.screenThree?.embed?.getPlayer().getQualities();
                            this.screenThree?.embed?.getPlayer().setQuality(this.getQuality(data.quality, qualities));
                            break;
                        case 4:
                            qualities = this.screenFour?.embed?.getPlayer().getQualities();
                            this.screenFour?.embed?.getPlayer().setQuality(this.getQuality(data.quality, qualities));
                            break;
                        default:
                            break;
                    }
                }
            })
        });
    }

    getQuality(quality, availableQualities) {
        if (quality === Quality.LOW) {
            return availableQualities[availableQualities.length - 1]?.group;
        } else if (quality === Quality.MID) {
            return availableQualities[Math.floor(availableQualities.length / 2)]?.group;
        } else if (quality === Quality.HIGH) {
            return availableQualities[1]?.group;
        } else {
            return 'chunked';
        }
    }

    generateUrlWithParams(url, queryParams) {
        if (!url) return null;

        if (this.isStreamable(url)) {
            return url;
        }

        if (this.isYouTube(url)) {
            url = this.convertToYouTubeEmbed(url);
        }

        if (url.indexOf('?') >= 0) {
            return url + '&' + queryParams.join('&');
        } else {
            return url + '?' + queryParams.join('&');
        }
    }

    extractYouTubeId(url) {
        let firstAttempt = url.match(/\?v=([^&]+)/);
        if (firstAttempt !== null && firstAttempt.length >= 2) {
            return `${firstAttempt[firstAttempt.length - 1]}`
        }

        let secondAttempt  = url.match(/https:\/\/youtu.be\/([^?]+)/);
        if (secondAttempt !== null && secondAttempt.length >= 2) {
            return `${secondAttempt[secondAttempt.length - 1]}`;
        }

        return null;
    }

    convertToYouTubeEmbed(url) {
        let youTubeId = this.extractYouTubeId(url);

        return youTubeId ? `https://www.youtube.com/embed/${youTubeId}` : url;
    }

    addYouTubeLoopHack(url) {
        let isYouTube = this.isYouTube(url);

        if (!isYouTube) return 'loop=1';

        let youTubeId = this.extractYouTubeId(url);

        return 'loop=1&playlist=' + youTubeId;
    }

    getQueryParamBySource(param, url, bool) {
        let isYouTube = this.isYouTube(url),
            isTwitch = this.isTwitch(url);

        return isYouTube ?
            ((param === 'muted' ? 'mute' : param) + '=' + (bool ? 1 : 0)) :
            (param + '=' + (bool ? 'true' : 'false'));
    }

    isStreamable(url) {
        return url.indexOf('streamable.com') >= 0;
    }

    isYouTube(url) {
        return url.indexOf('youtube.com') >= 0 || url.indexOf('youtu.be') >= 0;
    }

    isTwitch(url) {
        return url.indexOf('twitchcdn') >= 0 || url.indexOf('twitch.tv') >= 0;
    }

    getUniqueKey(content, screenIdx) {
        return content.url ? (screenIdx + '.' + content.url) : screenIdx;
    }

    renderClip(content, screenIdx) {
        if (this.isTwitch(content.url) || this.isStreamable(content.url)) {
            let classes = ['video'];

            if (content?.fullscreen) {
                classes.push('fullscreen');
            }

            return (
                <div className={classes.join(' ')} id={"screen-" + screenIdx} key={this.getUniqueKey(content, screenIdx)}>
                    <div>
                        <EmbedVideo
                            screen={screenIdx}
                            url={content?.url}
                            muted={content?.muted}
                            autoPlay={this.props.broadcastState.streamSettings.autoPlay}
                            fullscreen={content?.fullscreen || !screenIdx}
                            volume={content?.volume || this.props.broadcastState.streamSettings.volume}
                        />
                    </div>
                    {screenIdx ? <div className="shroud" /> : null}
                    {/*screenIdx ? this.renderControls(screenIdx) : null*/}
                </div>
            );
        } else {
            let classes = ['clip-iframe'];

            if (content?.fullscreen) {
                classes.push('fullscreen');
            }

            return (
                <div className={classes.join(' ')} id={"screen-" + screenIdx} key={this.getUniqueKey(content, screenIdx)}>
                    <div>
                        <iframe
                            ref={(elem) => this.setRef(screenIdx, elem)}
                            width={window.innerWidth / 2}
                            height={window.innerHeight / 2}
                            src={this.generateUrlWithParams(content.url, [
                                'parent=pursuit.poe-racing.com',
                                this.getQueryParamBySource('autoplay', content.url, this.props.broadcastState.streamSettings.autoPlay),
                                this.getQueryParamBySource('muted', content.url, content.muted),
                                this.addYouTubeLoopHack(content.url)
                            ])}
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                        />
                    </div>
                    {screenIdx ? <div className="shroud" /> : null}
                    {screenIdx && 0 ? this.renderOverlay(screenIdx) : null}
                </div>
            );
        }
    }

    renderTitle(idx) {
        if (idx) {
            return this.props.broadcastState.quadLayoutData[this.getQuadPropByIdx(idx)]?.data?.title;
        } else {
            return this.props.broadcastState.soloLayoutData.content?.data?.title;
        }
    }

    renderOverlay(idx) {
        if (!this.props.broadcastState.overlay.enabled) {
            return null;
        }

        let left = parseFloat((this.props.broadcastState.overlay.left || "0").replace('%', '')),
            top = parseFloat((this.props.broadcastState.overlay.top || "0").replace('%', ''));

        return (
            <div className="overlay">
                <div>
                    <span style={{
                        fontFamily: this.props.broadcastState.overlay.font.family,
                        fontSize: this.props.broadcastState.overlay.font.size + 'px',
                        color: this.props.broadcastState.overlay.font.color,
                        left: left + '%',
                        top: top + '%',
                        transform: 'translateX(' + (-1 * left) + '%) translateY(' + (-1 * top) + '%)'
                    }}>{this.renderTitle(idx)}</span>
                </div>
            </div>
        );
    }

    renderSoloScreen() {
        let data = this.props.broadcastState.soloLayoutData.content;
        let content = data?.data;

        return (
            <div className="solo-screen" style={{
                opacity: this.props.broadcastState.soloLayoutData?.content ? 1 : 0,
                pointerEvents: this.props.broadcastState.soloLayoutData?.content ? 'all' : 'none'
            }}>
                {this.renderScreen(data, content, 0)}
            </div>
        );
    }

    isAnythingFullscreen() {
        if (this.props.broadcastState.soloLayoutData?.content) {
            return true;
        } else if (this.props.broadcastState.quadLayoutData?.first?.data?.fullscreen) {
            return true;
        } else if (this.props.broadcastState.quadLayoutData?.second?.data?.fullscreen) {
            return true;
        } else if (this.props.broadcastState.quadLayoutData?.third?.data?.fullscreen) {
            return true;
        } else if (this.props.broadcastState.quadLayoutData?.fourth?.data?.fullscreen) {
            return true;
        }

        return false;
    }

    getCurrentFullscreenScreen() {
        if (this.props.broadcastState.soloLayoutData?.content) {
            return 0;
        } else if (this.props.broadcastState.quadLayoutData?.first?.data?.fullscreen) {
            return 1;
        } else if (this.props.broadcastState.quadLayoutData?.second?.data?.fullscreen) {
            return 2;
        } else if (this.props.broadcastState.quadLayoutData?.third?.data?.fullscreen) {
            return 3;
        } else if (this.props.broadcastState.quadLayoutData?.fourth?.data?.fullscreen) {
            return 4;
        }

        return null;
    }

    renderMediaScreen() {
        let data = this.props.broadcastState.media.content;
        let content = data?.data;

        if (!content || !content.url || this.isAnythingFullscreen()) {
            return null;
        }

        return (
            <div className="media-screen">
                <img src={content.url} alt="" />
            </div>
        );
    }

    renderContentWindows() {
        return Object.keys(this.props.broadcastState.quadLayoutData)
            .map(key => this.props.broadcastState.quadLayoutData[key])
            .map((data, mapIdx) => this.renderScreen(data, data?.data, mapIdx + 1));
    }

    renderScreen(data, content, screenIdx) {
        let classes = [];

        if (content && data.type === Entity.EXTERNAL) {
            return (
                <ExternalContent
                    ref={(elem) => this.setRef(screenIdx, elem)}
                    key={this.getUniqueKey(content, screenIdx)}
                    broadcastState={this.props.broadcastState}
                    fullscreen={content?.fullscreen}
                    screen={screenIdx}
                    content={content}
                />
            );
        } else if (content && data.type === Entity.CLIP) {
            return this.renderClip(content, screenIdx);
        } else if (content && data.type === Entity.STREAM) {
            return (
                <TwitchPlayer
                    ref={(elem) => this.setRef(screenIdx, elem)}
                    key={this.getUniqueKey(content, screenIdx)}
                    screen={screenIdx}
                    muted={content?.muted}
                    fullscreen={content?.fullscreen}
                    autoPlay={content?.autoPlay}
                    volume={content?.volume || this.props.broadcastState.streamSettings.volume}
                    quality={this.props.broadcastState.streamSettings.quality}
                    clip={data.type === Entity.CLIP ? content?.url : null}
                    stream={data.type === Entity.STREAM ? content?.url : null}
                >
                    {this.renderOverlay(screenIdx)}
                </TwitchPlayer>
            );
        } else if (content && data.type === Entity.MEDIA) {
            classes.push('media');

            if (content?.fullscreen) {
                classes.push('fullscreen');
            }

            return (
                <div className={classes.join(' ')}>
                    <img src={content?.url} alt="" />
                </div>
            );
        } else {
            return (
                <div className="nothing" id={"screen-" + screenIdx} key={'empty-' + screenIdx} />
            );
        }
    }

    getQuadPropByIdx(idx) {
        switch(idx) {
            case 1:
                return 'first';
            case 2:
                return 'second';
            case 3:
                return 'third';
            case 4:
                return 'fourth';
            default:
                return null;
        }
    }

    render() {
        return (
            <div className="broadcast-window">
                {this.renderContentWindows()}
                {this.renderSoloScreen()}
                {this.renderMediaScreen()}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        socket: state.socket,
        room: state.room,
        broadcastState: state.broadcastState
    };
};

export default connect(mapStateToProps)(MainBroadcast);