import React from 'react';
import { connect } from "react-redux";

import Layout from './Layout';
import Entity from "./Entity";

import Screen from "./Screen";
import Quality from "./quality";
import Role from "./Role";

class Layouts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.masterVolumeElem = null;
        this.queuedEvent = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.broadcastState.streamSettings?.volume !== prevProps.broadcastState.streamSettings?.volume && this.masterVolumeElem) {
            this.masterVolumeElem.value = this.props.broadcastState.streamSettings?.volume;
        }
    }

    validateSelectedLayout(layout) {
        switch(layout) {
            case Layout.SOLO:
                return this.validateSoloLayout()
            case Layout.QUAD:
                return this.validateQuadLayout();
            default:
                return false;
        }
    }

    validateSoloLayout() {
        let error = false;

        if (!this.props.broadcastState.soloLayoutData) {
            return `Failed to load solo layout data.`;
        }

        if (!this.props.broadcastState.soloLayoutData.content) {
            return `No stream assigned to solo layout.`;
        }

        return error;
    }

    validateQuadLayout() {
        let error = false;

        if (!this.props.broadcastState.quadLayoutData) {
            return `Failed to load quad layout data.`;
        }

        const contentContainers = ['first', 'second', 'third', 'fourth'];

        try {
            contentContainers.map((streamKey, idx) => {
                if (!this.props.broadcastState.quadLayoutData[streamKey]) {
                    throw Error(`Screen ${idx + 1} is not linked to a stream.\n\nAll screens must be connected to a stream before you may activate this layout.`);
                }
            });
        } catch (e) {
            error = e.message;
        }

        return error;
    }

    formatQuality(quality) {
        switch (quality) {
            case 'AUTO': return 'Auto';
            case 'HIGH': return 'High';
            case 'MID': return 'Mid';
            case 'LOW': return 'Low';
            default: return '???';
        }
    }

    renderCurrentQuality() {
        return (
            <span className="selected-quality">
                <span style={{ color: '#ddd' }}>{this.formatQuality(this.props.broadcastState.streamSettings.quality)}</span>
                <span>Quality</span>
            </span>
        );
    }

    renderQualities() {
        let qualities = Object.keys(Quality);

        return qualities.map((quality, key) => {
            return (
                <input key={key}
                       name="quality"
                       type="radio"
                       value={quality}
                       checked={quality === this.props.broadcastState?.streamSettings?.quality}
                       onChange={(e) => this.setQuality(quality)} />
            );
        });
    }

    setQuality(quality) {
        this.props.socket.emit('set quality', {
            room: this.props.room,
            quality
        });
    }

    toggleAutoPlay() {
        const {dispatch} = this.props;

        let autoPlay = !this.props.broadcastState.streamSettings.autoPlay;

        this.props.socket.emit('toggle auto-play', {
            room: this.props.room,
            autoPlay
        });

        dispatch({
            type: 'TOGGLE_AUTO_PLAY',
            autoPlay
        });
    }

    toggleAutoMute() {
        const {dispatch} = this.props;

        let autoMute = !this.props.broadcastState.streamSettings.autoMute;

        this.props.socket.emit('toggle auto-mute', {
            room: this.props.room,
            autoMute
        });

        dispatch({
            type: 'TOGGLE_AUTO_MUTE',
            autoMute
        });
    }

    // <span title="Toggle stream quality" className={['quality', content?.data.quality?.toLowerCase()].join(' ')} onClick={(e) => this.toggleQuality(e)}>{content?.data.quality}</span>

    onEditOverlay() {
        const {dispatch} = this.props;

        dispatch({
            type: 'SET_VIEW',
            view: 'SCREEN-OVERLAY'
        });
    }

    prevent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    renderBroadcastSettings() {
        const {dispatch} = this.props;

        if ([Role.ADMINISTRATOR, Role.BROADCASTER, Role.SUPER_USER].indexOf(this.props.user?.role) === -1) {
            return null;
        }

        return (
            <div className="broadcast-settings">
                <div className="edit-overlay">
                    <div className="title help" title="Add a basic caption to each content window.">
                        <span>Overlay</span>
                    </div>
                    <div className="button">
                        <button onClick={() => this.onEditOverlay()}>
                            Edit
                        </button>
                    </div>
                </div>
                <div className="stream-auto-play">
                    <div className="title help"
                         title="If media content should begin auto-playing instantly once dragged into the active broadcast state.">
                        <span>Auto-play</span>
                    </div>
                    <div className="auto-play">
                        <div className="switch-container">
                            <input type="checkbox" checked={this.props.broadcastState.streamSettings.autoPlay}/>
                            <label>
                                <div className="switch"
                                     onClick={() => this.toggleAutoPlay()}
                                     data-unchecked="Off"
                                     data-checked="On"/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="stream-quality">
                    <div className="title help"
                         title="The default quality of streams being dragged into the active broadcast state.">
                        {this.renderCurrentQuality()}
                    </div>
                    <div className="qualities">
                        {this.renderQualities()}
                    </div>
                </div>
                <div className="master-volume">
                    <div className="title help"
                         title="Update the default volume of dragged entities, and the current volume of all active streams and media content in the active broadcast state.">
                        Master Volume
                    </div>
                    <div className="volume">
                        <span className="slider">
                            <span className="volume-slider">
                                <input type="range"
                                       ref={elem => this.masterVolumeElem = elem}
                                       draggable={true}
                                       min="0"
                                       max="1"
                                       defaultValue={this.props.broadcastState.streamSettings.volume}
                                       step="0.01"
                                       onChange={(e) => {
                                           clearTimeout(this.queuedEvent);
                                           this.queuedEvent = setTimeout(() => {
                                               dispatch({
                                                   type: 'SET_MASTER_VOLUME',
                                                   volume: e.target.value ? parseFloat(e.target.value) : 0
                                               });
                                           }, 250);
                                       }}
                                       onInput={(e) => {
                                           clearTimeout(this.queuedEvent);
                                           this.queuedEvent = setTimeout(() => {
                                               dispatch({
                                                   type: 'SET_MASTER_VOLUME',
                                                   volume: e.currentTarget.value ? parseFloat(e.currentTarget.value) : 0
                                               });
                                           }, 250);
                                       }}
                                       onDragStart={(e) => this.prevent(e)}
                                />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderMediaScreen() {
        return (
            <Screen allowedContent={[Entity.MEDIA]}
                    source={this.props.broadcastState?.media?.content?.data?.url || null}
                    screen={null}
            />
        );
    }

    render() {
        if (this.props.view) {
            return null;
        }

        return (
            <div className="layouts">
                <div className="layout">
                    <div className="broadcast-screens">

                        <Screen allowedContent={[Entity.STREAM, Entity.EXTERNAL, Entity.CLIP, Entity.MEDIA]}
                                source={this.props.broadcastState.soloLayoutData.content?.data?.url || null}
                                screen={0}
                        />

                        {this.renderMediaScreen()}

                        <span className="break" />

                        <Screen allowedContent={[Entity.STREAM, Entity.EXTERNAL, Entity.CLIP, Entity.MEDIA]}
                                source={this.props.broadcastState.quadLayoutData.first?.data?.url || null}
                                screen={1}
                        />
                        <Screen allowedContent={[Entity.STREAM, Entity.EXTERNAL, Entity.CLIP, Entity.MEDIA]}
                                source={this.props.broadcastState.quadLayoutData.second?.data?.url || null}
                                screen={2}
                        />

                        <span className="break" />

                        <Screen allowedContent={[Entity.STREAM, Entity.EXTERNAL, Entity.CLIP, Entity.MEDIA]}
                                source={this.props.broadcastState.quadLayoutData.third?.data?.url || null}
                                screen={3}
                        />
                        <Screen allowedContent={[Entity.STREAM, Entity.EXTERNAL, Entity.CLIP, Entity.MEDIA]}
                                source={this.props.broadcastState.quadLayoutData.fourth?.data?.url || null}
                                screen={4}
                        />
                    </div>
                    {this.renderBroadcastSettings()}
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room,
        view: state.view,
        socket: state.socket,
        broadcastState: state.broadcastState
    };
};

export default connect(mapStateToProps)(Layouts);