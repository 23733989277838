import React from 'react';
import {connect} from 'react-redux';

import View from "./View";
import Role from "./Role";

class SubViewToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {dispatch} = this.props;

        return (
            <div className="sub-view-toggle ns">
                <div className={this.props.subview === View.PRIMARY ? 'selected filter-box' : 'filter-box'} onClick={() => dispatch({ type: 'SET_SUB_VIEW', view: View.PRIMARY })}>
                    <span>{this.props.user?.role === Role.BROADCASTER || this.props.user?.role === Role.SUPER_USER ? 'Broadcast' : 'Observe'}</span>
                </div>
                <div className={this.props.subview === View.EVENTS ? 'selected filter-box' : 'filter-box'} onClick={() => dispatch({ type: 'SET_SUB_VIEW', view: View.EVENTS })}>
                    <span>Events</span>
                </div>
                <div className={this.props.subview === View.ROLES ? 'selected filter-box' : 'filter-box'} onClick={() => dispatch({ type: 'SET_SUB_VIEW', view: View.ROLES })}>
                    <span>Roles</span>
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        subview: state.subview
    };
};

export default connect(mapStateToProps)(SubViewToggle);