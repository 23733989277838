import React from 'react';
import {connect} from 'react-redux';

class TextFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setTextFilter(text) {
        const {dispatch} = this.props;

        dispatch({
            type: 'SET_TEXT_FILTER',
            text
        });

        dispatch({
            type: 'SET_ITEM_LIST',
            items: this.props.items
        });
    }

    render() {
        return (
            <div className="text-filter">
                <input placeholder="Search ..." onChange={(e) => this.setTextFilter(e.target.value)} />
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        items: state.items
    };
};

export default connect(mapStateToProps)(TextFilter);