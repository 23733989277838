import React from 'react';

class ContentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            childrenWithProps: this.addPropsToChildren()
        };
    }

    addPropsToChildren() {
        return React.Children.map(this.props.children, (child) => {
            return React.isValidElement(child) ? React.cloneElement(child, { props: this.props }) : null;
        });
    }

    render() {
        return (
            <div className="content-container">
                {this.state.childrenWithProps}
            </div>
        );
    }
}

export default ContentContainer;