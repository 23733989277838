import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';

import MainBroadcast from './MainBroadcast'
import ProductionInterface from "./ProductionInterface";

import { ReactComponent as Spinner } from './media/img/spinner.svg';
import './style.scss';
import Dialog from "./Dialog";
import Entity from "./Entity";
import Pending from "./Pending";

class App extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);

        this.state = {
            siteOrigin: 'https://pursuit.poe-racing.com',
            child: null,
            isPending: false,
            inputFocused: false,
            errorMessage: null,
            parent: window?.opener, // There's a bug when you reuse a child tab and try to use it as a parent tab ... window.opener gets saved.
            twitchUser: this.props.user,
            openTwitchGate: this.props.user,
            showStreams: false,
            renderTwitchGate: !this.props.user || !this.props.room,
            roomKeyRegex: /^[\w\d]*$/,
            queryParams: queryParams,
            isLoading: !!queryParams.get('code') || false,
            minRoomKeyLength: 6,
            maxRoomKeyLength: 24,
            messages: [],
            itemType: null,
            messageWhitelist: ['https://pursuit.poe-racing.com'],
            twitchClientId: 'h1ey5xyxerfg72ff9ybro0x7277pqy'
        };

        this.onMessageHandler = (event) => {
            if (!this.state.messageWhitelist.includes(event.origin)) {
                return;
            }

            this.setState({
                messages: [...this.state.messages, event.data]
            });
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        window.addEventListener('message', this.onMessageHandler);

        this.setTitle();

        this.setSocketEventHandlers();

        // Just for ease-of-use while in development. Remove before launching into production.
        // window.socket = this.props.socket;

        if (this.state.queryParams.get('code')) {
            window.history.replaceState({}, document.title, "/");
            axios.get('https://pursuit-ws.poe-racing.com/auth/' + this.state.queryParams.get('code'))
                .then(response => {
                    if (!response) return;

                    let user = {
                        id: response.data.id,
                        name: response.data.name,
                        avatar: response.data.avatar,
                        role: response.data.role
                    };

                    this.setState({
                        openTwitchGate: true
                    });

                    dispatch({
                        type: 'SET_USER',
                        user
                    });

                    this.input.focus();
                });
        }

        if (this.props.authSetByLocalStorage) {
            this.enter(this.props.room);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setTitle();
    }

    componentWillUnmount() {
        this.unsetSocketEventHandlers();
    }

    unsetSocketEventHandlers() {
        this.props.socket.off('user joined');
        this.props.socket.off('user left');
        this.props.socket.off('user state');
        this.props.socket.off('broadcast state');
        this.props.socket.off('item list');
        this.props.socket.off('room state');
        this.props.socket.off('mute screen');
        this.props.socket.off('toggle fullscreen');
        this.props.socket.off('toggle auto-play');
        this.props.socket.off('set volume');
        this.props.socket.off('set master volume');
        this.props.socket.off('set quality');
        this.props.socket.off('chat message');
        this.props.socket.off('error message');
        this.props.socket.off('update local user role');
    }

    setSocketEventHandlers() {
        const {dispatch} = this.props;
        this.props.socket.on('user joined', (data) => {
            console.log('user joined');
            console.log(data);
        });

        this.props.socket.on('user state', (user) => {
            dispatch({
                type: 'SET_USER',
                user
            });
        })

        this.props.socket.on('user left', (data) => {
            console.log('user left');
            console.log(data);
        });

        this.props.socket.on('broadcast state', (data) => {
            dispatch({
                type: 'SET_BROADCAST_STATE',
                broadcastState: data
            });
        });

        this.props.socket.on('room state', (data) => {
            console.log('room state');
            console.log(data);
            dispatch({
                type: 'SET_ROOM_STATE',
                roomState: data
            });
        });

        this.props.socket.on('item list', (data) => {
            dispatch({
                type: 'SET_ITEM_LIST',
                items: data
            });
        });

        this.props.socket.on('mute screen', (data) => {
            dispatch({
                type: 'PARTIALLY_UPDATE_LOCAL_BROADCAST_STATE',
                screen: data.screen,
                property: 'muted',
                muted: data.muted
            });
        });

        this.props.socket.on('toggle fullscreen', (data) => {
            dispatch({
                type: 'PARTIALLY_UPDATE_LOCAL_BROADCAST_STATE',
                screen: data.screen,
                property: 'fullscreen',
                fullscreen: data.fullscreen
            });
        });

        this.props.socket.on('set volume', (data) => {
            console.log('set volume');
            console.log(data);
            dispatch({
                type: 'PARTIALLY_UPDATE_LOCAL_BROADCAST_STATE',
                screen: data.screen,
                property: 'volume',
                volume: data.volume
            });
        });

        this.props.socket.on('update local user role', (data) => {
            dispatch({
                type: 'SET_SELF_USER_ROLE',
                user: data.user,
                room: data.room,
                role: data.role
            });
        });

        this.props.socket.on('set master volume', (data) => {
            dispatch({
                type: 'UPDATE_STREAM_SETTINGS',
                volume: data.volume
            });
        });

        this.props.socket.on('toggle auto-play', (data) => {
            dispatch({
                type: 'UPDATE_STREAM_SETTINGS',
                autoPlay: data.autoPlay
            });
        });

        this.props.socket.on('set quality', (data) => {
            dispatch({
                type: 'SET_STREAM_QUALITY',
                quality: data.quality
            });
        });

        this.props.socket.on('chat message', (message) => {
            dispatch({
                type: 'ADD_MESSAGE',
                message
            });
        });

        this.props.socket.on('error message', (data) => {
            console.log('error');
            console.log(data);
        });
    }

    setTitle() {
        document.title = `Pursuit - ${this.isChildTab() ? 'Broadcast Window' : 'Controller Window'}`;
    }

    renderMessages() {
        return this.state.messages.map((message, idx) => {
            return <div key={idx}>{message}</div>;
        });
    }

    isChildTab() {
        return !!this.state.parent;
    }

    isParentTab() {
        return !!this.state.child;
    }

    post(message) {
        if (!this.isChildTab()) {
            return null;
        }

        this.state.parent && this.state.parent.postMessage(message, this.state.siteOrigin);
    }

    enter(room) {
        const { dispatch } = this.props;

        dispatch({
            type: 'SET_ROOM',
            broadcaster: false,
            room: room.toLowerCase()
        });

        this.setState({
            renderTwitchGate: false
        });
    }

    onKeyDown(e) {
        let room = this.input.value,
            isValidRoomKey = this.isValidRoomKey(room);

        if ((e.code === 'Enter' || e.code === 'NumpadEnter') && isValidRoomKey) {
            //this.enter(room);
            this.input.blur();
        }
    }

    onKeyUp(e) {
        let room = e.target.value,
            isValidRoomKey = this.isValidRoomKey(room);

        this.setState({
            errorMessage: !isValidRoomKey ? this.getInvalidRoomKeyErrorMessage() : null
        });
    }

    getInvalidRoomKeyErrorMessage() {
        let inputValue = this.input.value;

        if (!inputValue || !inputValue.length) {
            return null;
        } else if (!inputValue.match(this.state.roomKeyRegex)) {
            return 'Room key may only contain alphanumerical characters (A-Z, a-z, 0-9).';
        } else if (inputValue.length < this.state.minRoomKeyLength || inputValue > this.state.maxRoomKeyLength) {
            return `Room key must be between ${this.state.minRoomKeyLength} and ${this.state.maxRoomKeyLength} characters long.`;
        } else {
            return null;
        }
    }

    getInputValueIcon() {
        const room = this.input.value;

        if (room.length === 0) return null;

        return this.isValidRoomKey(room) ? 'fa-sign-in-alt' : 'fa-times';
    }

    getIconTitle() {
        if (!this.state.inputFocused) return null;

        return this.isValidRoomKey(this.input.value) ?
            'Press ENTER to Join Room' :
            'Invalid Room Key';
    }

    isValidRoomKey(room) {
        return room.length >= 6 && room.match(this.state.roomKeyRegex);
    }

    renderErrorMessage() {
        if (!this.state.errorMessage) return null;

        return (
            <div className="error-message">
                {this.state.errorMessage}
            </div>
        );
    }

    getTwitchAuthHref() {
        return `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${this.state.twitchClientId}&redirect_uri=https://pursuit.poe-racing.com`;
    }

    twitchAuth() {
        if (this.state.isLoading) return;

        this.setState({
            isLoading: true
        });

        if (!this.state.twitchUser) {
            window.location.href = this.getTwitchAuthHref();
        }
    }

    getIconClasses() {
        let classes = ['twitch'];

        if (this.state.openTwitchGate) {
            classes.push('open');
        }

        if (this.state.isLoading) {
            classes.push('loading');
        }

        return classes.join(' ');
    }

    renderIcon() {
        if (this.state.openTwitchGate) {
            return null;
        }

        if (this.state.isLoading) {
            return <Spinner className="spinner" />;
        }

        return (
            <span className={this.getIconClasses()} onClick={() => this.twitchAuth()} />
        );
    }

    renderTwitchGate() {
        if (!this.state.renderTwitchGate) {
            return null;
        }

        return (
            <div className={this.state.openTwitchGate ? 'twitch-gate open' : 'twitch-gate'}>
                <div className={!!this.state.queryParams.get('code') ? 'fade-in disabled' : 'fade-in'}>
                    <div className={this.state.openTwitchGate ? 'info open' : 'info'}>
                        <h2>
                            <div>Welcome to the</div>
                            <div>Pursuit Broadcasting Suite!</div>
                        </h2>
                        <p>
                            In order to continue, you must authenticate yourself using a Twitch account.
                            This account will act as the user that will be viewing the streams that you broadcast.
                        </p>
                        <p>
                            To improve the user experience, it is recommended that you subscribe to Twitch Turbo,
                            to prevent ads from being automatically displayed during your broadcasts.
                        </p>
                    </div>
                    {this.renderIcon()}
                </div>
                <div className={this.state.openTwitchGate ? 'open top' : 'top'} />
                <div className={this.state.openTwitchGate ? 'open bottom' : 'bottom'} />
            </div>
        );
    }

    renderWorkbench() {
        if (this.isChildTab()) {
            return (
                <MainBroadcast />
            );
        }

        if (!this.state.openTwitchGate) {
            return null;
        }

        let classes = ['workbench'];

        if (this.props.room) {
            classes.push('open');
        }

        return (
            <div className={classes.join(' ')}>
                <ProductionInterface />
            </div>
        );
    }

    getLabelClasses() {
        let classes = ['label'];

        if (!this.state.openTwitchGate) {
            classes.push('hidden');
        }

        if (this.state.inputFocused) {
            classes.push('focused');
        }

        return classes.join(' ')
    }

    renderJoinRoomScreen() {
        let classes = ['login'],
            iconClasses = ['fas'];

        iconClasses.push(this.state.inputFocused ? this.getInputValueIcon() : 'fa-key');

        if (this.props.room) {
            classes.push('room-entered');
        }

        return (
            <div className={classes.join(' ')}>
                <div className={this.getLabelClasses()} />
                <div className="input-container" style={this.state.inputFocused ? { width: '300px', paddingLeft: '24px' } : null}>
                    <div className="input">
                        <input
                            maxLength={this.state.maxRoomKeyLength}
                            key="pursuit-room-key"
                            ref={(elem) => this.input = elem}
                            autoComplete="off"
                            type="text"
                            name="pursuit-room-key"
                            onKeyDown={(e) => this.onKeyDown(e)}
                            onKeyUp={(e) => this.onKeyUp(e)}
                            onFocus={() => this.setState({ inputFocused: true })}
                            onBlur={() => this.isValidRoomKey(this.input.value) ? this.enter(this.input.value) : this.setState({ inputFocused: false })}
                        />
                    </div>
                    <div className="icon" title={this.getIconTitle()} onClick={() => this.input.focus()} style={this.state.inputFocused ? { marginLeft: '-48px' } : null}>
                        <i ref={elem => this.icon = elem} className={iconClasses.join(' ')} />
                    </div>
                </div>
                <div className="error-container" style={this.state.inputFocused ? { width: '300px', opacity: 1 } : null}>
                    {this.renderErrorMessage()}
                </div>
            </div>
        );
    }

    toggleFilterSetting(entityType) {
        const {dispatch} = this.props;

        dispatch({
            type: 'TOGGLE_FILTER_SETTING',
            entityType
        });
    }

    validUrl(url) {
        // TODO: Add actual regex's for each valid source.
        if (!url || url.length < 10) {
            return false;
        }

        return true;
    }

    isTwitchClip(url) {
        return url.length > 20 && url.indexOf('twitch') !== -1 && url.indexOf('clip') !== -1;
    }

    isStreamableClip(url) {
        return url.indexOf('streamable.com') >= 0;
    }

    fetchUrlByCode(code, type) {
        return axios.get('https://pursuit-ws.poe-racing.com/clip/' + code + '?type=' + type)
            .then(result => result.data.url);
    }

    extractCodeFromUrl(code) {
        let matches = null;

        matches = code.match(/embed[?]clip=([A-Za-z0-9-_]*)/);
        if (matches && matches.length > 1) return matches[1];

        matches = code.match(/twitch.tv\/\w+\/clip\/([A-Za-z0-9-_]*)/);
        if (matches && matches.length > 1) return matches[1];

        matches = code.match(/clips.twitch.tv\/([A-Za-z0-9-_]*)/);
        if (matches && matches.length > 1) return matches[1];

        matches = code.match(/streamable.com\/([A-Za-z0-9]*)/);
        if (matches && matches.length > 1) return matches[1];

        return null;
    }

    // Add 'pursuit' flag to all requests to poe-racing.com.
    addConditionalQueryParams(url) {
        if (!url)
            return url;

        if (url.indexOf('poe-racing.com') === -1)
            return url;

        let split = url.split('?');

        if (split.length === 1)
            return `${url}?pursuit`;
        else if (url.indexOf('?pursuit') === -1 && url.indexOf('&pursuit') === -1)
            return `${url}&pursuit`;
        else
            return url;
    }


    addEntity() {
        if (!this.state.itemType) {
            return alert('You must select an item type');
        }

        if (this.titleElem.value < 3) {
            return alert("Title must be at least 3 characters long");
        }

        if (!this.validUrl(this.urlElem.value)) {
            return alert('Invalid URL');
        }

        let code = this.extractCodeFromUrl(this.urlElem.value)
        console.log(code);

        if (this.state.itemType === Entity.CLIP && code) {
            if (this.isTwitchClip(this.urlElem.value)) {
                this.setState({ isPending: true });
                this.fetchUrlByCode(code, 'twitch').then(url => {
                    this.sendEntity({
                        title: this.titleElem.value.trim(),
                        url: this.addConditionalQueryParams(url.trim() || this.urlElem.value.trim()),
                        folder: this.props.folder
                    });
                    this.setState({isPending: false});
                });
            } else if (this.isStreamableClip(this.urlElem.value)) {
                this.setState({ isPending: true });
                this.fetchUrlByCode(code, 'streamable').then(url => {
                    this.sendEntity({
                        title: this.titleElem.value.trim(),
                        url: url.trim() || this.urlElem.value.trim(),
                        folder: this.props.folder
                    });
                    this.setState({isPending: false});
                })
            }
        } else {
            this.sendEntity({
                title: this.titleElem.value.trim(),
                url: this.addConditionalQueryParams(this.urlElem.value.trim()),
                folder: this.props.folder
            });
        }
    }

    sendEntity(attributes) {
        const {dispatch} = this.props;

        this.props.socket.emit('add entity', {
            room: this.props.room,
            user: this.props.user,
            type: this.state.itemType,
            attributes
        });

        dispatch({
            type: 'HIDE_DIALOG'
        });
    }

    renderDialogs() {
        if (!this.props.dialog) {
            return null;
        }

        const {dispatch} = this.props;

        return [
            <Dialog show={this.props.dialog === 'ITEM_FILTER'} onClose={() => dispatch({ type: 'HIDE_DIALOG' })}>
                <div className="item-settings">
                    <div className="option" onClick={() => this.toggleFilterSetting(Entity.EXTERNAL)}>
                        <span
                            className={['setting-status', this.props.itemSettings.filter.indexOf(Entity.EXTERNAL) >= 0 ? 'active' : 'inactive'].join(' ')}>
                            <span className="orb"/>
                            <span className="text">Include externals</span>
                        </span>
                    </div>

                    <div className="option" onClick={() => this.toggleFilterSetting(Entity.MEDIA)}>
                        <span
                            className={['setting-status', this.props.itemSettings.filter.indexOf(Entity.MEDIA) >= 0 ? 'active' : 'inactive'].join(' ')}>
                            <span className="orb"/>
                            <span className="text">Include media</span>
                        </span>
                    </div>
                    <div className="option" onClick={() => this.toggleFilterSetting(Entity.STREAM)}>
                        <span
                            className={['setting-status', this.props.itemSettings.filter.indexOf(Entity.STREAM) >= 0 ? 'active' : 'inactive'].join(' ')}>
                            <span className="orb"/>
                            <span className="text">Include streams</span>
                        </span>
                    </div>
                    <div className="option" onClick={() => this.toggleFilterSetting(Entity.CLIP)}>
                        <span
                            className={['setting-status', this.props.itemSettings.filter.indexOf(Entity.CLIP) >= 0 ? 'active' : 'inactive'].join(' ')}>
                            <span className="orb"/>
                            <span className="text">Include clips</span>
                        </span>
                    </div>
                </div>
            </Dialog>,
            <Dialog show={this.props.dialog === 'FOLDER_PATH'} onClose={() => dispatch({type: 'HIDE_DIALOG'})}>
                <div className="folder-container">
                    <ul className="folders">
                        <li>
                            {this.renderFolder('/')}
                            <ul>
                                {this.renderFolderPaths()}
                            </ul>
                        </li>
                    </ul>
                </div>
            </Dialog>,
            <Dialog show={this.props.dialog === 'ADD_ITEM'} onClose={(e) => dispatch({ type: 'HIDE_DIALOG' })}>
                <div className="entity-input">
                    <div className="entity-selector">
                        <div onClick={() => this.setState({ itemType: Entity.STREAM })} className={[Entity.STREAM.toLowerCase(), this.state.itemType === Entity.STREAM ? 'selected' : null].join(' ')}>Stream</div>
                        <div onClick={() => this.setState({ itemType: Entity.CLIP })} className={[Entity.CLIP.toLowerCase(), this.state.itemType === Entity.CLIP ? 'selected' : null].join(' ')}>Clip</div>
                        <div onClick={() => this.setState({ itemType: Entity.MEDIA })} className={[Entity.MEDIA.toLowerCase(), this.state.itemType === Entity.MEDIA ? 'selected' : null].join(' ')}>Media</div>
                        <div onClick={() => this.setState({ itemType: Entity.EXTERNAL })} className={[Entity.EXTERNAL.toLowerCase(), this.state.itemType === Entity.EXTERNAL ? 'selected' : null].join(' ')}>External</div>
                    </div>
                    <input placeholder="Enter the title of the item" ref={elem => this.titleElem = elem} />
                    <input placeholder="Enter the URL to the resource" ref={elem => this.urlElem = elem} />
                    <button style={{ width: '80px' }} onClick={() => this.addEntity()} disabled={this.state.isPending}>
                        {this.state.isPending ? <Pending /> : 'Import'}
                    </button>
                </div>
            </Dialog>
        ];
    }

    renderFolder(folder) {
        let classes = ['fas', this.props.folder === folder ? 'fa-folder-open' : 'fa-folder'];

        return (
            <div className="folder" onClick={() => this.changeFolder(folder)}>
                <span className="icon">
                    <i className={classes.join(' ')} />
                </span>
                <span>{folder === '/' ? `/${this.props.room}` : folder}</span>
            </div>
        );
    }

    changeFolder(folder) {
        const {dispatch} = this.props;

        dispatch({
            type: 'CHANGE_FOLDER',
            folder
        });
    }

    renderFolderPaths() {
        return this.props.folders.map((folder, idx) => {
            return (
                <li key={folder}>
                    {this.renderFolder(folder)}
                </li>
            );
        });
    }

    render() {
        return (
            <div className="container">
                {this.renderTwitchGate()}
                {this.renderJoinRoomScreen()}
                {this.renderWorkbench()}
                {this.renderDialogs()}
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.user,
        room: state.room,
        folder: state.folder,
        folders: state.folders,
        dialog: state.dialog,
        itemSettings: state.itemSettings,
        socket: state.socket,
        authSetByLocalStorage: state.authSetByLocalStorage
    };
};

export default connect(mapStateToProps)(App);
