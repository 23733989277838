import React from 'react';

class ExternalContent extends React.Component {
    constructor(props) {
        super(props);

        this.iframeContainer = null;
    }

    getQueryParamBySource(param, url, bool) {
        let isYouTube = this.isYouTube(url),
            isTwitch = this.isTwitch(url);

        return isYouTube ?
            ((param === 'muted' ? 'mute' : param) + '=' + (bool ? 1 : 0)) :
            (param + '=' + (bool ? 'true' : 'false'));
    }

    generateUrlWithParams(url, queryParams) {
        if (!url) return null;

        if (this.isStreamable(url)) {
            return url;
        }

        if (this.isYouTube(url)) {
            url = this.convertToYouTubeEmbed(url);
        }

        if (url.indexOf('?') >= 0) {
            return url + '&' + queryParams.join('&');
        } else {
            return url + '?' + queryParams.join('&');
        }
    }

    convertToYouTubeEmbed(url) {
        let youTubeId = this.extractYouTubeId(url);

        return youTubeId ? `https://www.youtube.com/embed/${youTubeId}` : url;
    }

    extractYouTubeId(url) {
        let firstAttempt = url.match(/\?v=([^&]+)/);
        if (firstAttempt !== null && firstAttempt.length >= 2) {
            return `${firstAttempt[firstAttempt.length - 1]}`
        }

        let secondAttempt  = url.match(/https:\/\/youtu.be\/([^?]+)/);
        if (secondAttempt !== null && secondAttempt.length >= 2) {
            return `${secondAttempt[secondAttempt.length - 1]}`;
        }

        return null;
    }

    isStreamable(url) {
        return url.indexOf('streamable.com') >= 0;
    }

    isYouTube(url) {
        return url.indexOf('youtube.com') >= 0 || url.indexOf('youtu.be') >= 0;
    }

    isTwitch(url) {
        return url.indexOf('twitchcdn') >= 0 || url.indexOf('twitch.tv') >= 0;
    }

    render() {
        let classes = [];

        classes.push('external-iframe');
        classes.push('interactable');

        if (this.props.fullscreen) {
            classes.push('fullscreen');
        }

        const height = Math.floor(window.innerHeight / 2);
        const width = Math.floor(height * (16 / 9));

        return (
            <div className={classes.join(' ')}>
                <div className="external-content"
                     style={this.props.screen && !this.props.fullscreen ? { width: width + 'px', height: height + 'px' } : null}
                     ref={(elem) => this.iframeContainer = elem}
                     id={"screen-" + (this.props.screen)}
                >
                    <iframe
                        src={this.generateUrlWithParams(this.props.content.url, [
                            'parent=pursuit.poe-racing.com',
                            this.getQueryParamBySource('autoplay', this.props.content.url, this.props.broadcastState.streamSettings.autoPlay),
                            this.getQueryParamBySource('muted', this.props.content.url, this.props.content?.muted)
                        ])}
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                    />
                </div>
                {this.props.screen ? <div className="shroud"/> : null}
            </div>
        );
    }
}

export default ExternalContent;